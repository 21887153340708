import React, { useState } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  Active,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { DragHandle, SortableItem } from './sorting-components/sortable-item';
import { SortableOverlay } from './sorting-components/sortable-overlay';
import { ReportColumn } from '../../models/report-details';

export const SortableListColumns = ({ itemsList, renderItem, onChange }) => {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = itemsList.find((item) => item.uuid === active?.id);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDragStart = (active) => {
    setActive(active);
  };
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const activeIndex = itemsList.findIndex(({ uuid }) => uuid === active.id);
      const overIndex = itemsList.findIndex(({ uuid }) => uuid === over.id);
      const updatedList = arrayMove(itemsList, activeIndex, overIndex);
      const updatedListWithColumnOrder = updatedList.map(
        (item: ReportColumn, index) => ({
          ...item,
          columnOrder: index,
        })
      );
      onChange(updatedListWithColumnOrder);
    }
  };
  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        handleDragStart(active);
      }}
      onDragEnd={handleDragEnd}
      onDragCancel={() => {
        setActive(null);
      }}>
      <SortableContext items={itemsList}>
        {itemsList.map((item) => (
          <React.Fragment key={item.uuid}>{renderItem(item)}</React.Fragment>
        ))}
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
};
SortableListColumns.Item = SortableItem;
SortableListColumns.DragHandle = DragHandle;
