import React from 'react';
import DatePicker from '@mui/lab/DatePicker';
import FormControl from '@mui/material/FormControl';
import { InputType } from '../input-type.enum';
import { TextField } from '@mui/material';
import { formatDate } from '../../localization-helper';

export type CubitDatePickerAdapterProps = {
  type: InputType.DateRange | InputType.Date;
};

const CubitDatePickerAdapter: React.FC<CubitDatePickerAdapterProps> = (
  props: any
) => {
  const {
    id,
    name,
    input,
    field,
    label,
    style,
    variant,
    meta,
    formcontrolprops,
    ...rest
  } = props;

  const inputFormat = formatDate();
  const value = input.value || null;
  const touched = meta.touched;
  const errorText = meta.error;

  return (
    <FormControl
      variant='standard'
      {...formcontrolprops}
      component='fieldset'
      style={style}>
      <DatePicker
        id={id || name || label}
        margin='none'
        label={label}
        placeholder={label}
        autoOk
        inputFormat={inputFormat}
        inputVariant={variant}
        renderInput={(params) => <TextField variant='standard' {...params} />}
        value={value ? new Date(value) : null}
        onChange={(date) => input.onChange(date?.toString())}
        error={touched && errorText && errorText.length > 0}
        invalidDateMessage='Ugyldig datoformat'
        {...rest}
      />
    </FormControl>
  );
};

export default CubitDatePickerAdapter;
