import { useEffect } from 'react';
import './App.scss';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { LOCAL_STORAGE_KEY_SESSION_KEY } from './services/FetchService';
import ReportsPage from './pages/reports-page';
import ConfigPage from './pages/config-page';
import { ReportsActions } from './redux/reports-actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './redux/app-store';
import { LoginPage } from './pages/login-page';
import { useAuth } from './redux/auth/auth-selectors';
import { actionAuthInit } from './redux/auth/auth-actions';
import { RevolvingDot } from 'react-loader-spinner';
import { history } from './index';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorSnackbar } from './components/error-snackbar';
import DashboardPage from './pages/dashboard-page';
import { ErrorPage } from './pages/error-page';

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    textAlign: 'center',
  },
  loaderIcon: {
    flex: 1,
    textAlign: 'center',
  },
}));

function App() {
  const dispatch = useDispatch();
  const userConfig = useSelector((state: AppState) => state.reports.userConfig);

  const auth = useAuth();
  const styles = useStyles();

  useEffect(() => {
    if (!!auth.sessionKey) {
      ReportsActions.actionLoadReportGroups()(dispatch);
      ReportsActions.actionLoadTenantConfigs()(dispatch);
      ReportsActions.actionLoadUserConfig()(dispatch);
      ReportsActions.actionLoadTenantSettings(auth.sessionKey)(dispatch);
    }
  }, [auth, dispatch]);

  useEffect(() => {
    dispatch(actionAuthInit());
  }, [dispatch]);

  return (
    <div className='App'>
      <ErrorSnackbar />
      {!auth.isInitialized && (
        <div className={styles.loader}>
          <RevolvingDot
            wrapperClass={styles.loaderIcon}
            wrapperStyle={{ display: 'inline-block' }}
            color='#385690'
            height={100}
            width={100}
          />
        </div>
      )}
      <Router history={history}>
        <Route
          path={`/sso-login/:token`}
          component={(props) => {
            localStorage.setItem(
              LOCAL_STORAGE_KEY_SESSION_KEY,
              props.match.params.token
            );
            return <Redirect to={`/`} />;
          }}
        />

        {auth.isInitialized && !auth.sessionKey && (
          <Switch>
            <Route exact path='/login' component={LoginPage} />
            <Redirect to='/login' />
            <Route path='*' component={ErrorPage} />
          </Switch>
        )}

        {auth.isInitialized && auth.sessionKey && (
          <Switch>
            <Route exact path='/dashboard' component={DashboardPage} />
            <Route
              exact
              path='/reports/:reportGroupCode/:reportCode'
              component={ReportsPage}
            />

            {userConfig?.isReportManagementAllowed && (
              <Switch>
                <Route exact path='/config' component={ConfigPage} />
                <Route
                  exact
                  path='/config/:reportDefinitionCode'
                  component={ConfigPage}
                />
                <Route path='*' component={ErrorPage} />
              </Switch>
            )}

            <Route exact path='/' render={() => <Redirect to='/dashboard' />} />
            <Route path='*' component={ErrorPage} />
          </Switch>
        )}
      </Router>
    </div>
  );
}

export default App;
