import { IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useUser } from '../redux/auth/auth-selectors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '10px',
  },
  reportTitle: {
    paddingLeft: '6px',
    fontWeight: 'bold',
  },
}));
type Props = {
  report?: string;
  group?: string;
};

export const TopBarReturn: React.FC<Props> = ({ report, group }) => {
  const styles = useStyles();
  const history = useHistory();
  const handleClick = () => {
    if (report && group) {
      history.push(`/reports/${group}/${report}`);
    } else {
      history.push(`/dashboard/`);
    }
  };
  return (
    <div className={styles.iconContainer}>
      <IconButton
        edge='start'
        color='inherit'
        aria-label='back'
        onClick={handleClick}
        size='large'>
        <ArrowBackIcon />
      </IconButton>
    </div>
  );
};
