import { t } from 'i18next';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { TabPanel } from '@mui/lab';
import { ListItemText, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {
  ReportColumn,
  ReportResultDefinition,
} from '../../models/report-details';
import { BaseQueryColumnInfo } from '../../models/report-info';
import { ReportButton } from '../dashboard/report-button';
import { SelectedColumnBlock } from './column-edit';
import { SortableListColumns } from '../sorting/sortable-list-columns';
import {
  addNewColumn,
  changeColumnSelection,
  deleteColumn,
  handleColumnOrderChange,
  saveSelectedColumnDetails,
} from './columns-tab-helper';

type ReportColumnsTabProps = {
  setSelectedColumn: (column: ReportColumn) => void;
  selectedColumn: ReportColumn;
  setSelectedReport: any;
  selectedReport: any;
  setNewColumns: (column: ReportColumn[]) => void;
  newColumns: ReportColumn[];
  reportResult: ReportResultDefinition;
  potentialColumns: BaseQueryColumnInfo[];
  setHasUnsavedChanges: (val: boolean) => void;
  tabName: string;
  solidifyNewResult: (result: ReportResultDefinition) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  TabPanel: {
    padding: '0 0 16px 0',
  },
  sortableItemContainer: {
    width: '100%',
    marginBottom: '1px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    padding: '16px 0',
  },
  pageContainer: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
  },
  sortableListContainer: {
    width: '100%',
  },
  columnsContainer: {
    display: 'flex',
    width: '33%',
    marginRight: '16px',
  },
  columnsHeader: {
    display: 'flex',
    padding: '16px',
    backgroundColor: '#fff',
    marginBottom: '1px',
    fontWeight: 'bold',
  },
  itemTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  typography: {
    display: 'inline',
    color: '#bbb',
    verticalAlign: 'middle',
  },
  selectedColumnContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: '16px',
    width: '60%',
  },
}));

export const ReportColumnsTab: React.FC<ReportColumnsTabProps> = (props) => {
  const styles = useStyles();
  const {
    setSelectedColumn,
    selectedColumn,
    setNewColumns,
    setSelectedReport,
    selectedReport,
    newColumns,
    reportResult,
    potentialColumns,
    setHasUnsavedChanges,
    tabName,
    solidifyNewResult,
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const columns = reportResult.columnDefinitions;

  return (
    <TabPanel className={styles.TabPanel} value={tabName} tabIndex={1}>
      <div className={styles.buttonContainer}>
        <ReportButton
          onClick={() =>
            addNewColumn(
              setNewColumns,
              setSelectedColumn,
              setHasUnsavedChanges,
              t,
              columns,
              newColumns
            )
          }
          title={t('NEW_COLUMN', 'ny kolonne')}
        />
      </div>

      <div className={styles.pageContainer}>
        <div className={styles.columnsContainer}>
          {columns.length ? (
            <div className={styles.sortableListContainer}>
              <div className={styles.columnsHeader}>
                {t('COLUMNS', 'Kolonner')}
              </div>
              <SortableListColumns
                itemsList={columns}
                onChange={(updatedColumns) =>
                  handleColumnOrderChange(
                    updatedColumns,
                    setHasUnsavedChanges,
                    selectedReport,
                    setSelectedReport
                  )
                }
                renderItem={(item) => (
                  <SortableListColumns.Item id={item.uuid}>
                    <div
                      className={styles.sortableItemContainer}
                      key={item.id}
                      onClick={() =>
                        changeColumnSelection(
                          item,
                          item.uuid,
                          setSelectedColumn,
                          setSelectedIndex
                        )
                      }>
                      <div>
                        <ListItemText
                          primary={
                            <div className={styles.itemTextContainer}>
                              <SortableListColumns.DragHandle />
                              {item?.displayName}
                              <Typography
                                className={styles.typography}
                                component='span'
                                variant='body2'
                                color='initial'>
                                {item?.isAggregateColumn
                                  ? ' (' +
                                    t('CUSTOM_DATA', 'Tilpasset data') +
                                    ')'
                                  : ''}
                              </Typography>
                            </div>
                          }></ListItemText>
                      </div>

                      <div>
                        {item === selectedColumn && (
                          <DeleteIcon
                            sx={{ color: 'rgba(0, 0, 0, 0.26)' }}
                            onClick={(e) =>
                              deleteColumn(
                                e,
                                selectedColumn.id,
                                reportResult,
                                columns,
                                selectedColumn,
                                setSelectedColumn,
                                setHasUnsavedChanges
                              )
                            }
                          />
                        )}

                        <KeyboardArrowRightIcon />
                      </div>
                    </div>
                  </SortableListColumns.Item>
                )}
              />
            </div>
          ) : null}
        </div>
        {!!selectedColumn && (
          <div className={styles.selectedColumnContainer}>
            <SelectedColumnBlock
              initialValues={selectedColumn}
              setSelectedColumn={setSelectedColumn}
              index={selectedIndex}
              baseQuery={selectedReport.baseQueryCode}
              selectedColumn={selectedColumn}
              newColumn={newColumns}
              setHasUnsavedChanges={setHasUnsavedChanges}
              setNewColumn={setNewColumns}
              reportResult={reportResult}
              onSubmit={(x) =>
                saveSelectedColumnDetails(
                  x,
                  selectedIndex,
                  selectedColumn,
                  newColumns,
                  reportResult,
                  setNewColumns,
                  setSelectedColumn,
                  setSelectedIndex,
                  setHasUnsavedChanges,
                  solidifyNewResult,
                  selectedReport,
                  setSelectedReport
                )
              }
              potentialColumns={potentialColumns}
              open></SelectedColumnBlock>
          </div>
        )}
      </div>
    </TabPanel>
  );
};
