import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

type ConfirmDialogProps = {
  title: string;
  children?: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => void;
  confirmationText?: string;
};
const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    paddingBottom: '8px',
  },
}));
const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { title, open, setOpen, onConfirm, confirmationText } = props;
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='confirm-dialog'>
      <DialogTitle id='confirm-dialog'>
        {t('CONFIRM_ACTION', 'Bekreft handling')}
      </DialogTitle>
      <DialogContent>{title}</DialogContent>
      <DialogActions>
        <Button variant={'text'} onClick={() => setOpen(false)}>
          {t('CANCEL', 'Avbryt')}
        </Button>
        <Button
          variant={'contained'}
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          type='submit'>
          {confirmationText || t('YES', 'ja')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
