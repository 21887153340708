import { IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { ReportInfo } from '../models/report-info';
import { useDispatch, useSelector } from 'react-redux';
import ReportConfigClient from '../clients/ReportConfigClient';
import { ReportsActions } from '../redux/reports-actions';
import { useEffect, useState } from 'react';
import { AppState } from '../redux/app-store';

const useStyles = makeStyles((theme: Theme) => ({
  draftContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
  },
  favouriteIcon: {
    color: '#385690',
  },
  button: {
    padding: '0px',
    '& .MuiButtonBase-root-MuiIconButton-root': {
      padding: '0px',
    },
  },
}));
type Props = {
  report: ReportInfo;
  setShowAll: (bool: boolean) => void;
  showAll: boolean;
};
export const FavouriteChip: React.FC<Props> = ({
  report,
  setShowAll,
  showAll,
}) => {
  const styles = useStyles();
  const [isFavorite, setIsFavorite] = useState(report.isFavorite);
  const dispatch = useDispatch();
  const favoriteReports = useSelector(
    (state: AppState) => state.reports.reportFavoriteList
  );
  const favoriteReportsList = favoriteReports.reduce((acc, reportGroup) => {
    return acc.concat(
      reportGroup.reports.filter((report) => report.isFavorite)
    );
  }, []);
  useEffect(() => {
    const isReportFavorite = favoriteReportsList.some(
      (favoriteReport) => favoriteReport.code === report.code
    );
    setIsFavorite(isReportFavorite);
  }, [favoriteReportsList, report.code]);

  const markFavourite = (e, reportCode) => {
    e.stopPropagation();

    ReportConfigClient.markReportFavourite(reportCode).subscribe(() => {
      ReportsActions.actionLoadFavorites()(dispatch);
    });
    setIsFavorite(true);
    showAll && setShowAll(showAll);
  };
  const unmarkFavourite = (e, reportCode) => {
    e.stopPropagation();
    ReportConfigClient.unmarkReportFavourite(reportCode).subscribe(() => {
      ReportsActions.actionLoadFavorites()(dispatch);
    });
    setIsFavorite(false);
  };

  return (
    <div className={styles.draftContainer}>
      {isFavorite ? (
        <IconButton
          className={styles.button}
          onClick={(e) => unmarkFavourite(e, report.code)}>
          <StarIcon className={styles.favouriteIcon} />
        </IconButton>
      ) : (
        <IconButton
          className={styles.button}
          onClick={(e) => markFavourite(e, report.code)}>
          <StarBorderIcon />
        </IconButton>
      )}
    </div>
  );
};
