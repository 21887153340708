import { AnyAction } from 'redux';
import {
  ACTION_AUTH_INITIALIZED,
  ACTION_AUTH_LOGIN,
  ACTION_AUTH_LOGIN_ERROR,
  ACTION_AUTH_LOGIN_SUCCESS,
  ACTION_AUTH_LOGOUT,
} from './auth-actions';
import { makeReducer } from '../../utils';
import { ActionReducer, Reducers } from '../../utils/make-reducer';
import { User } from '../../models/user';

export type AuthState = {
  isInitialized: boolean;
  isLoading: boolean;
  sessionKey?: string;
  user?: User;
  error?: string;
};

type AuthReducer = ActionReducer<AuthState, AnyAction>;

const initialState: AuthState = {
  isInitialized: false,
  isLoading: false,
  sessionKey: undefined,
  user: undefined,
};

const login: AuthReducer = (state, action) => ({
  ...state,
  isLoading: true,
});

const logout: AuthReducer = (state, action) => ({
  ...initialState,
  isInitialized: true,
});

const loginSuccess: AuthReducer = (state, action) => ({
  ...state,
  isInitialized: true,
  isLoading: false,
  sessionKey: action.data.session.sessionKey,
  user: action.data.user,
});

const loginError: AuthReducer = (state, action) => ({
  ...state,
  isInitialized: true,
  isLoading: false,
  sessionKey: undefined,
  user: undefined,
  error: action.error,
});

const initialized: AuthReducer = (state, action) => ({
  ...state,
  isInitialized: true,
});

const reducers: Reducers<AuthState> = {
  [ACTION_AUTH_LOGIN]: login,
  [ACTION_AUTH_LOGIN_SUCCESS]: loginSuccess,
  [ACTION_AUTH_LOGIN_ERROR]: loginError,
  [ACTION_AUTH_INITIALIZED]: initialized,
  [ACTION_AUTH_LOGOUT]: logout,
};

export const authReducer = makeReducer<AuthState>(reducers, initialState);
