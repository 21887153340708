import { Observable } from 'rxjs';

const lanternUrl = (url: string): string => process.env.REACT_APP_API_URL + url;

export default class AuthClient {
  public static login(request: any): Observable<any> {
    const requestBody = { body: JSON.stringify(request) };
    const requestMethod = { method: 'POST' };
    return new Observable((observer) => {
      fetch(lanternUrl('/auth/login'), {
        ...requestMethod,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          pragma: 'no-cache',
          'Cache-Control': 'no-cache',
        },
        ...requestBody,
      })
        .then((response) => {
          if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (
              !contentType ||
              contentType.indexOf('application/json') === -1
            ) {
              observer.next(response);
              observer.complete();
            } else {
              response.json().then((data) => {
                observer.next(data);
                observer.complete();
              });
            }
          } else {
            observer.error();
            observer.complete();
          }
        })
        .catch((err) => observer.error(err));
    });
  }
}
