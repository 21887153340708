import { ReportInfo } from '../models/report-info';
import { FilterObject } from '../models/filter-object';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { WhiteOutlinedButton } from './general/buttons/white-outlined-button';
const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  rightElements: {
    display: 'flex',
    paddingLeft: '24px',
  },
  leftElements: {
    display: 'flex',
    alignItems: 'center',
  },
}));
export const TopBar: React.FC<{
  pageTitle?: any;
  report?: ReportInfo;
  appliedFilters?: FilterObject;
  downloadExcel?: () => void;
  isLoading?: boolean;
  leftElements?: any;
  rightElements?: any;
  excel?: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <div id='header'>
      <div className={styles.headerContainer}>
        <div className={styles.leftElements}>
          <Link to='/dashboard' style={{ display: 'flex' }}>
            <div className='logo' />
          </Link>
          {props.leftElements && props.leftElements.map((element) => element)}
        </div>
        <div>
          <div className={styles.rightElements}>
            {props.excel && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '12px',
                }}>
                <WhiteOutlinedButton
                  text={t('DOWNLOAD_EXCEL', 'last ned rapport til excel')}
                  onClick={props.downloadExcel}
                />
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.rightElements &&
                props.rightElements.map((element) => element)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
