import { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { map, Subscription } from 'rxjs';
import EditIcon from '@mui/icons-material/Edit';
import ReportsClient from '../clients/ReportsClient';
import { TopBar } from '../components/top-bar';
import { DynamicReport } from '../models/dynamic-report';
import { ReportGroupInfo, ReportInfo } from '../models/report-info';
import { RevolvingDot } from 'react-loader-spinner';
import { FilterObject } from '../models/filter-object';
import structuredClone from '@ungap/structured-clone';
import { DynamicReportTable } from '../components/dynamic-report-table';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/app-store';
import { saveDownloadViewModelAs } from '../utils/file-download';
import { useTranslation } from 'react-i18next';
import { Filters } from '../components/filters';
import { TopBarReturn } from '../components/top-bar-return';
import { WhiteOutlinedButton } from '../components/general/buttons/white-outlined-button';

type ReportsPageProps = RouteComponentProps<{
  reportGroupCode?: string;
  reportCode?: string;
}>;

const ReportsPage: React.FC<ReportsPageProps> = (props: ReportsPageProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const reportList = useSelector(
    (state: AppState) => state.reports.reportGroupList
  );
  const [reportView, setReportView] = useState<DynamicReport>(null);
  const [filters, setFilters] = useState<any>(null);

  const [loadingSub, setLoadingSub] = useState<Subscription>(null);
  const [loadingFiltersSub, setLoadingFiltersSub] =
    useState<Subscription>(null);
  const [appliedFilters, setAppliedFilters] = useState<FilterObject>({
    appliedFilters: [],
  });
  const [selectedReport, setSelectedReport] = useState<ReportInfo>(null);
  const [pendingFilters, setPendingFilters] = useState<FilterObject>({
    appliedFilters: structuredClone(appliedFilters.appliedFilters) || [],
  });
  const reportGroupCode = props.match
    ? props.match.params.reportGroupCode
    : null;
  const reportCode = props.match ? props.match.params.reportCode : null;

  var reportInfo = reportList.find(
    (x) => x.code === reportGroupCode
  ) as ReportGroupInfo;
  useEffect(() => {
    setFilters(null);
    setReportView(null);
    if (selectedReport) {
      var sub = ReportsClient.getReportFilters(selectedReport.code)
        .pipe(
          map((filters) => {
            setFilters(filters);
            setLoadingFiltersSub(null);
          })
        )
        .subscribe();
      setLoadingFiltersSub(sub);
    }
  }, [selectedReport]);

  const reloadReport = (appliedFilters: FilterObject) => {
    setReportView(null);

    if (loadingSub) {
      loadingSub.unsubscribe();
      setLoadingSub(null);
    }

    if (!!selectedReport) {
      var sub = ReportsClient.getReport(selectedReport.code, appliedFilters)
        .pipe(
          map((report) => {
            setReportView(report);
            setLoadingSub(null);
          })
        )
        .subscribe();
      setLoadingSub(sub);
    }
  };

  const applyFilters = (filters: FilterObject, isReload: boolean) => {
    if (isReload) {
      reloadReport(filters);
    }
    setAppliedFilters(structuredClone(filters));
  };

  useEffect(() => {
    if (reportInfo?.reports.length) {
      let report = reportInfo.reports.find(
        (f) => f.code === reportCode
      ) as ReportInfo;
      setSelectedReport(report);
    }

    setReportView(null);
  }, [
    props.match.params.reportGroupCode,
    props.match.params.reportCode,
    reportList,
    reportInfo?.reports,
    reportCode,
  ]);

  const downloadExcel = () => {
    setAppliedFilters(structuredClone(pendingFilters));
    var sub = ReportsClient.getReportExcel(
      selectedReport.code,
      pendingFilters
    ).subscribe((response) => {
      saveDownloadViewModelAs(response);
      setLoadingSub(null);
    });

    setLoadingSub(sub);
  };
  let rightElements = [];

  if (reportInfo && reportInfo?.isGroupEditAllowed) {
    rightElements.push(
      <WhiteOutlinedButton
        key={'outlined-button'}
        startIcon={<EditIcon />}
        text={t('CONFIG_REPORT', 'KONFIGURER')}
        onClick={() => {
          history.push(`/config/${selectedReport?.code}`);
        }}
      />
    );
  }
  return (
    <Fragment>
      <TopBar
        excel
        report={selectedReport}
        appliedFilters={appliedFilters}
        downloadExcel={downloadExcel}
        isLoading={!!loadingSub || !!loadingFiltersSub}
        leftElements={[
          <TopBarReturn key={'top-bar-return'} />,
          <div key={'group-info'}>
            <span>{reportInfo && reportInfo.label}</span>:{' '}
            <span>
              <b>{selectedReport && selectedReport.label}</b>
            </span>
          </div>,
        ]}
        rightElements={rightElements}
      />
      <div className='pageContainer'>
        {!!filters && (
          <>
            <Filters
              filters={filters}
              appliedFilters={appliedFilters}
              applyFilters={applyFilters}
              isLoading={!!loadingSub || !!loadingFiltersSub}
              pendingFilters={pendingFilters}
              setPendingFilters={setPendingFilters}
            />
            {!!reportView && (
              <DynamicReportTable reportGroup={reportView}></DynamicReportTable>
            )}
          </>
        )}
        {(!!loadingSub || !!loadingFiltersSub) && (
          <div className='loaderContainer'>
            <RevolvingDot
              wrapperStyle={{ display: 'inline-block' }}
              color='#385690'
              height={100}
              width={100}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default withRouter(ReportsPage);
