import { ReportHistoryEvent } from './report-history-event';
import { TenantConfiguration } from './tenant-config';

export type ReportGroup = {
  id?: number;
  displayName: string;
  code: string;
  categoryName: string;
  isGloballyVisible: boolean;
  reports: ReportDefinition[];
  allowedTenants: TenantConfiguration[];
};

export type SaveReportGroup = Omit<
  ReportGroup,
  'reports' | 'allowedTenants'
> & {
  tenantIds: string[];
};

export type ReportBaseQuery = {
  id: number;
  groupDefinitionId: number;
  code: string;
  name: string;
  description: string;
  sqlQuery: string;
};

export type ReportDefinition = {
  id?: number;
  baseQueryId?: number;
  baseQueryCode?: string; //Not coming from API
  code: string;
  name: string;
  versionNumber: number;
  innerGroupByColumnName?: string;
  resultDefinitions: ReportResultDefinition[];
  filterDefinitions: ReportFilter[];
  historyEvents: ReportHistoryEvent[];
  hasUnsavedChanges?: boolean; //Not coming from API
};

export type ReportResultDefinition = {
  type: ReportResultTypeEnum;
  orderByColumnName?: string;
  columnDefinitions: ReportColumn[];
  isNew?: boolean;
};

export enum ReportResultTypeEnum {
  ViewModel = 0,
  CountReport = 1,
  Excel = 2,
  CSV = 3,
  XML = 4,
}

export type ReportColumn = {
  id?: number;
  uuid: string; //Not coming from API
  sqlColumn?: string;
  selectColumn?: string;
  displayName: string;
  columnOrder: number;
  isAggregateColumn: boolean;
};

export type ReportFilter = {
  uuid: string; //Not coming from API
  id?: number;
  displayName: string;
  operator: ReportFilterOperator;
  reportInputType?: ReportFilterInputType;
  sqlColumn: string;
  selectColumn?: string;
  clusionType: string;
  clusionItems?: string[];
  defaultValueMin?: any;
  defaultValueMax?: any;
  isHidden?: boolean;
  jsonParams?: string;
  filterOrder: number;
};

export enum ReportFilterOperator {
  Equals = 0,
  GreaterThan = 1,
  LessThan = 2,
  InBetween = 3,
}

export enum ReportFilterInputType {
  None = 0,
  Number = 1,
  SelectList = 2,
  Text = 3,
  MultiSelectText = 4,
  Date = 5,
  DateTime = 6,
  Boolean = 7,
  MultiSelect = 8,
}
