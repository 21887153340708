import React, { ChangeEvent, useState } from 'react';

import styles from './cubit-table.module.css';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import {
  CubitTableHeaderProps,
  CubitTableProps,
  CubitTableColumn,
  OrderDirection,
} from './cubit-table.types';
import { CubitTableHeader } from './cubit-table-header';
import TableBody from '@mui/material/TableBody';
import _ from 'lodash';
import get from 'lodash/get';
import find from 'lodash/find';
import TablePagination from '@mui/material/TablePagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionCubitTableOrder,
  actionCubitTableSelect,
} from './cubit-table-actions';
import {
  arraySection,
  arrayDistinct,
  arrayRemove,
  arrayRemoveAtIndex,
} from '../../utils';
import { CubitCheckbox } from '../cubit-inputs/cubit-checkbox/cubit-checkbox';
import classNames from 'classnames';
import { filter } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  tablePagination: {
    paddingLeft: '6px',
    fontWeight: 'bold',
    margin: '0px',
    '& .MuiTablePagination-displayedRows': {
      margin: '0px',
    },

    '& .MuiInputBase-root': {
      display: 'none',
    },
  },
  table: {
    '& .MuiPaper-root': {
      borderRadius: '0px',
      boxShadow: 'none',
    },
  },
}));
const DEFAULT_ROWS_PER_PAGE = [20, 25, 30];

const CubitTable: React.FC<CubitTableProps> = (props) => {
  const {
    columns,
    data,
    selectable,
    sorting,
    paging,
    name: tableName,
    onRowClick,
    flat,
    style,
  } = props;
  console.log(props);

  const tableStyles = useStyles();

  let initialSortingDirection: 'asc' | 'desc' = 'asc';

  const firstSortableColumn = find(
    columns,
    (column) => column.sortable !== false
  );
  let initialSortingColumn = firstSortableColumn
    ? firstSortableColumn.key
    : columns[0].key;

  if (sorting && typeof sorting === 'object') {
    initialSortingDirection = sorting.direction;
    initialSortingColumn = sorting.by;
  }

  const dispatch = useDispatch();
  const onOrderChange = (
    tableName: string,
    orderBy: string,
    orderDirection: OrderDirection
  ) => dispatch(actionCubitTableOrder(tableName, orderBy, orderDirection));

  const order =
    useSelector(
      (state: any) =>
        state.table[tableName] && state.table[tableName].orderDirection
    ) || initialSortingDirection;
  console.log(order);

  const orderBy =
    useSelector(
      (state: any) => state.table[tableName] && state.table[tableName].orderBy
    ) || initialSortingColumn;

  const initialRowsPerPage =
    paging && typeof paging === 'object'
      ? paging.rowsPerPage[0]
      : DEFAULT_ROWS_PER_PAGE[0];

  const [page, setPage] = useState(0);
  const selected = useSelector((state: any) =>
    state.table[tableName] && state.table[tableName].selection
      ? state.table[tableName].selection
      : []
  );

  const setSelected = (tableName: string, selection: any[]) =>
    dispatch(actionCubitTableSelect(tableName, selection));
  const [previouslyToggledCheckboxIndex, setPreviouslyToggledCheckboxIndex] =
    useState(0);

  let tableHeaderProps: CubitTableHeaderProps = {
    columns: columns,
  };

  let transformedData = data;
  const rowClickHandler = (
    row: any,
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    if (typeof onRowClick === 'function') {
      onRowClick(row, event);
    }
  };

  let renderRowCheckbox: (
    index: number,
    id: string,
    selected: boolean
  ) => JSX.Element | null = (index, id, selected) => null;

  if (selectable) {
    const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
      const isSelectAllCurrentlyChecked = event.currentTarget.checked;
      let selected = [];

      if (isSelectAllCurrentlyChecked) {
        if (typeof selectable === 'function') {
          selected = filter(data, selectable).map((n) => n.id);
        } else {
          selected = data.map((n) => n.id);
        }
      }

      setSelected(tableName, selected);
    };

    const handleCheckboxChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      index: number,
      id: string
    ) => {
      const nativeEvent = event.nativeEvent as unknown as React.MouseEvent;
      const isShiftKeyPressed = nativeEvent.shiftKey;
      const itemAlreadySelected = selected.indexOf(id) !== -1;

      let newSelected: any[] = [];

      if (isShiftKeyPressed) {
        const selection = arraySection(
          data,
          previouslyToggledCheckboxIndex,
          index,
          true
        );
        const selectableItemsInSelection =
          typeof selectable === 'function'
            ? filter(selection, selectable)
            : selection;
        const selectableItemsIds = selectableItemsInSelection.map(
          (i: any) => i.id
        );

        if (itemAlreadySelected) {
          newSelected = arrayRemove(selected, selectableItemsIds);
        } else {
          newSelected = arrayDistinct(
            newSelected.concat(selected, selectableItemsIds)
          );
        }
      } else {
        if (itemAlreadySelected) {
          newSelected = arrayRemoveAtIndex(selected, selected.indexOf(id));
        } else {
          newSelected = newSelected.concat(selected, id);
        }
      }

      setPreviouslyToggledCheckboxIndex(index);

      setSelected(tableName, newSelected);
    };

    renderRowCheckbox = (index: number, rowData: any, selected: boolean) => {
      const isRowSelectable =
        typeof selectable === 'function' ? selectable(rowData) : selectable;

      return isRowSelectable ? (
        <td className={styles.checkboxCell}>
          <CubitCheckbox
            checked={selected}
            onChange={(event) => handleCheckboxChange(event, index, rowData.id)}
            onClick={(e) => e.stopPropagation()}
          />
        </td>
      ) : (
        <td />
      );
    };

    tableHeaderProps = {
      ...tableHeaderProps,
      select: {
        selectedCount: selected.length,
        totalSelectableCount:
          typeof selectable === 'function'
            ? filter(data, selectable).map((n) => n.id).length
            : data.length,
        onSelectAll: handleSelectAllChange,
      },
    };
  }
  if (sorting) {
    const handleSort = (
      event: React.MouseEvent<HTMLButtonElement>,
      property: string
    ) => {
      console.log(property);

      const isDesc = orderBy === property && order === 'desc';
      onOrderChange(tableName, property, isDesc ? 'asc' : 'desc');
    };

    tableHeaderProps = {
      ...tableHeaderProps,
      sorting: { direction: order, by: orderBy, onSort: handleSort },
    };
    let isDateField = false;
    for (const item of transformedData) {
      const value = item[orderBy];
      if (value === '-') {
        continue;
      }
      const parsedDate = new Date(value);
      if (!isNaN(parsedDate.getTime())) {
        isDateField = true;
        break;
      } else {
        isDateField = false;
        break;
      }
    }

    transformedData = _.orderBy(
      transformedData,
      [
        (item) => item[orderBy] === '-', // moving "-" values to the end
        (item) => {
          const value = item[orderBy];
          if (isDateField) {
            const parsedDate = new Date(value);
            return parsedDate.getTime();
          }
          return value;
        },
      ],
      ['asc', order]
    );
  }
  //#endregion

  let pagingElement: JSX.Element | null = null;

  if (paging) {
    const handleChangePage = (event: any, newPage: any) => {
      setPage(newPage);
    };

    pagingElement = (
      <TablePagination
        className={tableStyles.tablePagination}
        component='div'
        labelRowsPerPage={''}
        count={data.length}
        rowsPerPage={20}
        page={page}
        showFirstButton
        showLastButton
        onPageChange={handleChangePage}
      />
    );

    transformedData = transformedData.slice(
      page * initialRowsPerPage,
      page * initialRowsPerPage + initialRowsPerPage
    );
  }

  const renderTableRow = (
    index: number,
    row: any,
    columns: CubitTableColumn[]
  ) => {
    const isItemSelected = selected.indexOf(row.id) !== -1;

    return (
      <tr
        key={row.id || index}
        className={classNames(styles.tableRow, {
          [styles.tableRowSelected]: isItemSelected,
          [styles.tableRowClickable]: typeof onRowClick === 'function',
        })}
        // role='checkbox'
        tabIndex={-1}
        onClick={(event) => rowClickHandler(row, event)}>
        {renderRowCheckbox(index, row, isItemSelected)}

        {columns.map((column, index) => (
          <td
            key={index}
            className={styles.tableCell}
            style={{ textAlign: column.align, width: column.width }}>
            {column.getDisplayableElement
              ? column.getDisplayableElement(row)
              : get(row, column.key)}
          </td>
        ))}
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <>
        <div style={{ width: '100%', overflow: 'auto' }}>
          <Table style={style}>
            <CubitTableHeader {...tableHeaderProps} />
            <TableBody>
              {transformedData.map((row: any, index: number) =>
                renderTableRow(index, row, columns)
              )}

              {/* {whiteSpaceElement} */}
            </TableBody>
          </Table>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {pagingElement}
        </div>
      </>
    );
  };

  return flat ? (
    renderTable()
  ) : (
    <Paper
      style={{ borderRadius: '0px', boxShadow: 'none' }}
      className={tableStyles.table}>
      {renderTable()}
    </Paper>
  );
};

export default CubitTable;
