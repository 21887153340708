import React from 'react'

import styles from '../cubit-checkbox/cubit-checkbox.module.css'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { InputType } from '../input-type.enum'

import classnames from 'classnames'

export type CubitCheckboxAdapterProps = { type: InputType.Checkbox }

export const CubitCheckboxAdapter: React.FC<CubitCheckboxAdapterProps> = (props: any) => {
    const { input, disabled, label } = props

    const renderCheckboxIcon = (isChecked: boolean, isDisabled: boolean): any => {
        return isChecked ? <CheckBoxIcon className={styles.checkboxSelected}  /> : <CheckBoxOutlineBlankIcon />
    }

    return (
        <div style={{ width: "100%" }}>
            <div
                style={{ display: "inline-block", verticalAlign: "middle", paddingLeft: "0" }}
                className={classnames(styles.checkbox, {
                    [styles.checkboxDisabled]: disabled,
                })}
            >
                {renderCheckboxIcon(input.value, disabled)}

                <input                    
                    checked={input.value}
                    className={styles.checkboxInput}
                    onChange={input.onChange}
                    onClick={input.onClick}
                    disabled={disabled}
                    type="checkbox"
                />
            </div>
            <span style={{ verticalAlign: "middle" }}>{label}</span>
        </div>
    )
}
