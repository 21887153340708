import { useTranslation } from 'react-i18next';
import { FilterObject } from '../../models/filter-object';
import {
  ReportFilterInfo,
  ReportFilterOption,
} from '../../models/report-filter';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Checkbox, FormControlLabel, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import ReportsClient from '../../clients/ReportsClient';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/app-store';
import { ReportsActions } from '../../redux/reports-actions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles((theme: Theme) => ({
  formGroup: {
    display: 'flex',
    padding: '10px 0',
    backgroundColor: '#fff',
  },

  formGroupLabel: {
    paddingLeft: '6px',
    fontWeight: 'bold',
    display: 'flex',
    width: '200px',
    minWidth: '200px',
  },
  checkboxLabel: {
    paddingLeft: '6px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#3f558c',
    },
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  loadMoreButton: {
    marginTop: '10px',
    color: 'grey',
  },
  loadMoreButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
export const MultiSelectFilter = (props: {
  filter: ReportFilterInfo;
  pendingFilters: FilterObject;
  setPendingFilters: (f: FilterObject) => void;
}) => {
  const { filter, pendingFilters, setPendingFilters } = props;
  const { t } = useTranslation();

  const styles = useStyles();

  const [page, setPage] = useState(2);
  const [options, setOptions] = useState<ReportFilterOption[]>(filter.options);

  const [hasMoreOptions, setHasMoreOptions] = useState(true);
  useEffect(() => {
    ReportsClient.loadOptions(filter.filterId, page).subscribe(
      (response) => {
        if (response.length) {
          setHasMoreOptions(true);
        } else {
          setHasMoreOptions(false);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  const fetchData = () => {
    ReportsClient.loadOptions(filter.filterId, page).subscribe(
      (response) => {
        if (response.length < 100) {
          setOptions((prevOptions) => [...prevOptions, ...response]);
          setHasMoreOptions(false);
        } else {
          setOptions((prevOptions) => [...prevOptions, ...response]);
          setPage((prevPage) => prevPage + 1);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleLoadMore = () => {
    fetchData();
  };
  const onChange = (code: string, checked: boolean) => {
    var filterItem = pendingFilters.appliedFilters.find(
      (x) => x.filterId === filter.filterId
    );

    if (!filterItem) {
      filterItem = {
        filterId: filter.filterId,
        values: [],
      };
      pendingFilters.appliedFilters.push(filterItem);
    }

    if (checked && !filterItem.values.includes(code)) {
      filterItem.values.push(code);
    } else if (!checked && filterItem.values.includes(code)) {
      filterItem.values.splice(filterItem.values.indexOf(code), 1);
    }
    setPendingFilters({ ...pendingFilters });
  };

  const isChecked = (option: ReportFilterOption): boolean => {
    return !!filterItem && filterItem.values.includes(option.code);
  };
  const hasMore = useSelector(
    (state: AppState) => state.reports.hasMoreOptions
  );
  if (filter.isHidden) {
    return <span></span>;
  }

  var filterItem = pendingFilters.appliedFilters.find(
    (x) => x.filterId === filter.filterId
  );

  return (
    <>
      <div className={styles.formGroup}>
        <div className={styles.formGroupLabel}>{filter.displayName}</div>
        <div className={styles.options}>
          {options.map((option, index) => (
            <FormControlLabel
              className={styles.checkboxLabel}
              key={index}
              control={
                <Checkbox
                  className={styles.checkbox}
                  checked={isChecked(option)}
                  onChange={(event) =>
                    onChange(option.code, event.target.checked)
                  }
                  value={option.value}
                />
              }
              label={option.value}
            />
          ))}
          <div className={styles.loadMoreButtonContainer}>
            {hasMoreOptions && (
              <Button
                className={styles.loadMoreButton}
                variant='text'
                onClick={handleLoadMore}>
                {t('load_more', 'Load more options')} <KeyboardArrowDownIcon />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
