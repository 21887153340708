import { DateTime } from 'luxon';

export const digits00 = (val) => {
  return ('0' + val).slice(-2);
}

export const dateString = (date: Date) => {
  return `${digits00(date.getDate())}.${(digits00(date.getMonth() + 1))}.${date.getFullYear()}`;
}

export const dateTimeString = (date: Date) => {
  const localDate = DateTime.fromJSDate(date).toLocal();
  return localDate.toFormat('dd.MM.yyyy HH:mm:ss');
}

export function groupBy<T>(list : T[], keyGetter: ((T) => any)) {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}

export function generateUUID() {
  var d = new Date().getTime();
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;
      if(d > 0){
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export function objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}