export type ReportHistoryEvent = {
  eventType: ReportHistoryEventType,
  id: number,
  responsibleUserId: string,
  responsibleUserName: string,
  timestamp: Date,
  versionNumber: number
}

export enum ReportHistoryEventType {
  Created = 0,
  Updated = 1,
  Reverted = 2,
  Deleted = 100
}