import { Action, AnyAction, Reducer } from 'redux'
import produce from 'immer'

export type ActionReducer<S = any, A extends Action = AnyAction> = (state: S, action: A) => S

export type Reducers<T> = {
    [key: string]: ActionReducer<T, AnyAction>
}

export const makeReducer = <T>(reducers: Reducers<T>, initialState: T) => (
    state: T = initialState,
    action: AnyAction,
) => (reducers[action.type] ? produce(state, (newState: T) => reducers[action.type](newState, action)) : state)
