import React, { useMemo, useState } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { Active } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import './sortable-list.css';
import { SortableOverlay } from './sorting-components/sortable-overlay';
import { DragHandle, SortableItem } from './sorting-components/sortable-item';
import ReportConfigClient from '../../clients/ReportConfigClient';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/app-store';

export const SortableList = ({ items, onChange, renderItem, showAll }) => {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const reports = showAll ? items : items.slice(0, 5);
  const handleSort = (reportCode, sortId) => {
    ReportConfigClient.sortReport(reportCode, sortId).subscribe();
  };
  const searchValue = useSelector(
    (state: AppState) => state.dashboard.searchTerm
  );
  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);
          handleSort(items[activeIndex].code, overIndex);
          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}>
      <SortableContext items={items}>
        <ul className='SortableList' role='application'>
          {searchValue.length >= 3
            ? reports
                .filter((report) =>
                  report.label
                    .toLowerCase()
                    .includes(searchValue?.toLowerCase())
                )
                .map((item) => (
                  <React.Fragment key={item.id}>
                    {renderItem(item)}
                  </React.Fragment>
                ))
            : reports.map((item) => (
                <React.Fragment key={item.id}>
                  {renderItem(item)}
                </React.Fragment>
              ))}
        </ul>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
};

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
