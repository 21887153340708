import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as TRANSLATIONS_NO from './locale/no.json';
import * as TRANSLATIONS_EN from './locale/en.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: TRANSLATIONS_EN,
    },
    nb: {
      translation: TRANSLATIONS_NO,
    },
  },
});

const language = localStorage.getItem('language') || 'nb';

i18n.changeLanguage(language);
