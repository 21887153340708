import { InputType } from './input-type.enum';
import { CubitSelectAdapterProps } from './react-form-adapters/cubit-select-adapter';
import { CubitSwitchAdapterProps } from './react-form-adapters/cubit-switch-adapter';
import { CubitTextFieldAdapterProps } from './react-form-adapters/cubit-text-field-adapter';
import { CubitCheckboxGroupAdapterProps } from './react-form-adapters/cubit-checkbox-group-adapter';
import { CubitRadioGroupAdapterProps } from './react-form-adapters/cubit-radio-group-adapter';
import { CSSProperties } from 'react';
import { CubitSelectGroupAdapterProps } from './react-form-adapters/cubit-select-group-adapter';

type FieldComponentPropsByInputType<T> = T extends InputType.Checkbox
  ? CubitCheckboxGroupAdapterProps
  : T extends InputType.Radio
  ? CubitRadioGroupAdapterProps
  : T extends InputType.NumberRange
  ? CubitTextFieldAdapterProps
  : T extends InputType.Select
  ? CubitSelectAdapterProps
  : T extends InputType.Switch
  ? CubitSwitchAdapterProps
  : T extends InputType.TextField
  ? CubitTextFieldAdapterProps
  : T extends InputType.SelectGroup
  ? CubitSelectGroupAdapterProps
  : never;

type FieldPropsByInputType<T> = T extends InputType.DateRange
  ? FieldProps<T>
  : T extends InputType.Date
  ? FieldProps<T>
  : T extends InputType.Checkbox
  ? FieldPropsForCheckbox<T>
  : T extends InputType.Radio
  ? FieldPropsWithOptions<T>
  : T extends InputType.NumberRange
  ? FieldPropsForInput<T>
  : T extends InputType.Select
  ? FieldPropsForSelect<T>
  : T extends InputType.SliderRange
  ? FieldPropsForSlider<T>
  : T extends InputType.Switch
  ? FieldProps<T>
  : T extends InputType.TextField
  ? FieldPropsForInput<T>
  : T extends InputType.SelectGroup
  ? FieldPropsForSelectGroup<T>
  : never;

type FieldTypeByInputType<T> = T extends
  | InputType.DateRange
  | InputType.NumberRange
  ? RangeField<T>
  : T extends InputType.Checkbox | InputType.Radio | InputType.Select
  ? FieldBase<T>
  : T extends InputType.Date
  ? FieldBase<T>
  : T extends InputType.SliderRange
  ? FieldBase<T>
  : T extends InputType.Switch
  ? FieldBase<T>
  : T extends InputType.TextField
  ? FieldBase<T>
  : T extends InputType.SelectGroup
  ? SelectGroup<T>
  : never;

type FieldBase<T extends AvailableInputTypes> = {
  type: T;
  props: FieldPropsByInputType<T>;
};

type RangeField<T extends AvailableInputTypes> = {
  type: T;
  label: string;
  name: string;
  from: { props: FieldPropsByInputType<T> };
  to: { props: FieldPropsByInputType<T> };
};

type SelectGroup<T extends AvailableInputTypes> = {
  type: T;
  props: FieldPropsByInputType<T>;
};

type FieldPropsForInput<T> = FieldProps<T> & {
  type: 'text' | 'number' | 'password';
};

type FieldPropsWithOptions<T> = FieldProps<T> & {
  options: { label: string; value: string }[];
};

type FieldPropsForCheckbox<T> = FieldProps<T> & {
  valueisobject?: boolean;
  options?: { label: string; value: number | string }[];
};

type FieldPropsForSelect<T> = FieldProps<T> & {
  valueisobject?: boolean;
  options: SelectOptions[];
};

type FieldPropsForSelectGroup<T> = FieldProps<T> & {
  valueisobject?: boolean;
  instances: SelectGroupOptions[];
};

export type SelectOptions =
  | SelectOptionTitle
  | SelectOptionDivider
  | SelectOptionValue;
type SelectOptionTitle = { label: string; type: SelectOptionType };
type SelectOptionDivider = { type: SelectOptionType };
type SelectOptionValue = {
  label: string;
  value: number | string | { type: string; value: string };
  style?: CSSProperties;
};
type SelectGroupOptions = {
  label: string;
  name: string;
  options: SelectOptions[];
};

type FieldPropsForSlider<T> = FieldProps<T> & {
  marks?: { [key: string]: string | { style?: any; label?: string } };
  step?: number;
  allowCross?: boolean;
  min?: number;
  max?: number;
};

type InputTypes = { [P in InputType]: string };

type FieldProps<T> = {
  name: string;
  label: string;
  component: React.FunctionComponent<FieldComponentPropsByInputType<T>>;
};

type Field<T> = FieldTypeByInputType<T>;

export enum SelectOptionType {
  Title = 'TITLE',
  Divider = 'DIVIDER',
}

export type AvailableInputTypes = keyof InputTypes;
export type FormField<T extends AvailableInputTypes> = Field<T>;

type select = () => FormField<InputType.Select>;
type selectGroup = () => FormField<InputType.SelectGroup>;
type numberRange = () => FormField<InputType.NumberRange>;
type dateRange = () => FormField<InputType.DateRange>;
type date = () => FormField<InputType.Date>;
type checkbox = () => FormField<InputType.Checkbox>;
type radio = () => FormField<InputType.Radio>;
type sliderRange = () => FormField<InputType.SliderRange>;
type switchInput = () => FormField<InputType.Switch>;
type textFieldInput = () => FormField<InputType.TextField>;

export type FormFields =
  | select
  | numberRange
  | dateRange
  | date
  | checkbox
  | radio
  | sliderRange
  | switchInput
  | textFieldInput
  | selectGroup;

export type AvailableFormFields = {
  [key: string]:
    | select
    | selectGroup
    | numberRange
    | dateRange
    | date
    | checkbox
    | sliderRange
    | switchInput
    | textFieldInput;
};
