import { createAppStore } from '../redux/app-store';

export const formatDate = () => {
  const state = createAppStore().getState();
  const defaultFormat = 'dd.MM.yyyy';
  const dateFormat = state?.reports.tenantSettings.dateFormat || defaultFormat;
  return dateFormat;
};
export const formatTime = () => {
  const state = createAppStore().getState();
  const defaultFormat = 'HH:mm';
  const timeFormat = state.reports.tenantSettings.timeFormat || defaultFormat;
  return timeFormat;
};
