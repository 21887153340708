import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterObject } from '../../models/filter-object';
import { ReportFilterInfo } from '../../models/report-filter';
import { InputLabel, MenuItem, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Select from '@mui/material/Select';

const useStyles = makeStyles((theme: Theme) => ({
  formGroup: {
    backgroundColor: '#fff',
    padding: '10px 0',
    justifyContent: 'space-between',
    '&.MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row',
      padding: '10px 0',
    },
  },

  formGroupLabel: {
    paddingLeft: '6px',
    fontWeight: 'bold',
    display: 'flex',
    width: '200px',
    minWidth: '200px',
  },
}));
export const SelectFilter = (props: {
  filter: ReportFilterInfo;
  pendingFilters: FilterObject;
  setPendingFilters: (f: FilterObject) => void;
}) => {
  const { filter, pendingFilters, setPendingFilters } = props;
  const { t } = useTranslation();
  const styles = useStyles();
  const [updateCount, setUpdateCount] = useState(0);

  const forceUpdate = () => {
    return setUpdateCount((updateCount) => updateCount + 1);
  };

  function handleChange(code: string) {
    var filterItem = pendingFilters.appliedFilters.find(
      (x) => x.filterId == filter.filterId
    );
    if (!!code) {
      if (!filterItem) {
        filterItem = {
          filterId: filter.filterId,
          values: [],
        };
        pendingFilters.appliedFilters.push(filterItem);
      }

      filterItem.values = [code];
      forceUpdate();
    } else if (filterItem) {
      pendingFilters.appliedFilters.splice(
        pendingFilters.appliedFilters.indexOf(filterItem),
        1
      );
      forceUpdate();
    }
  }

  if (filter.isHidden) {
    return <span></span>;
  }
  return (
    <div className={styles.formGroup}>
      <div style={{ display: 'flex', width: '100%' }}>
        <div className={styles.formGroupLabel}>{filter.displayName}</div>
        <Select
          defaultValue={filter.options[1].code}
          label='Age'
          onChange={(e) => handleChange(e.target.value)}>
          {filter.options.map((option) => (
            <MenuItem value={option.code}>{option.value}</MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};
