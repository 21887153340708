import { TFunction } from 'i18next';
import { ReportColumn } from '../../models/report-details';
import { generateUUID } from '../../utils/common-utils';
import pascalcase from '../../utils/pascal-case';

export const changeColumnSelection = (
  column: ReportColumn,
  index: number,
  setSelectedColumn,
  setSelectedIndex
) => {
  setSelectedColumn(null);
  setSelectedIndex(index);
  setTimeout(() => {
    setSelectedColumn(column);
  }, 10);
};

export const addNewColumn = (
  setNewColumns,
  setSelectedColumn,
  setHasUnsavedChanges,
  t: TFunction,
  columns: ReportColumn[],
  newColumns: ReportColumn[]
) => {
  const column: ReportColumn = {
    uuid: generateUUID(),
    displayName: t('NEW_COLUMN', 'ny kolonne'),
    columnOrder: columns.length,
    isAggregateColumn: false,
  };
  setNewColumns(
    newColumns && newColumns.length ? [...newColumns, column] : [column]
  );
  setSelectedColumn(null);
  setTimeout(() => {
    setSelectedColumn(column);
  }, 10);
  setHasUnsavedChanges(true);
};

export const deleteColumn = (
  event: any,
  id: number,
  reportResult,
  columns,
  selectedColumn,
  setSelectedColumn,
  setHasUnsavedChanges
) => {
  event.stopPropagation();
  reportResult.columnDefinitions = reportResult.columnDefinitions.filter(
    (x) => x.id != id
  );
  reportResult.columnDefinitions.filter((column) => column.id !== id);
  var removed = columns.filter((column) => column.id === id);

  if (selectedColumn == removed[0]) {
    setSelectedColumn(null);
  }
  if (removed && removed[0].selectColumn == reportResult.orderByColumnName) {
    reportResult.orderByColumnName = null;
  }
  setSelectedColumn(null);
  setHasUnsavedChanges(true);
};

export const saveSelectedColumnDetails = (
  changes: ReportColumn,
  index: number,
  selectedColumn,
  newColumns,
  reportResult,
  setNewColumns,
  setSelectedColumn,
  setSelectedIndex,
  setHasUnsavedChanges,
  solidifyNewResult,
  selectedReport,
  setSelectedReport
) => {
  const savedColumn = {
    ...selectedColumn,
    ...changes,
    sqlColumn: changes.isAggregateColumn
      ? changes.sqlColumn
      : changes.selectColumn,
    selectColumn: changes.isAggregateColumn
      ? pascalcase(changes.displayName)
      : changes.selectColumn,
  };

  if (
    !!newColumns &&
    newColumns.some((column) => column.uuid === savedColumn.uuid)
  ) {
    reportResult.columnDefinitions.push(savedColumn);
    setNewColumns(
      newColumns.filter((column) => column.uuid !== savedColumn.uuid)
    );
  } else {
    reportResult.columnDefinitions = reportResult.columnDefinitions.filter(
      (x) => x.uuid != savedColumn.uuid
    );
    reportResult.columnDefinitions.push(savedColumn);
  }
  changeColumnSelection(
    savedColumn,
    index,
    setSelectedColumn,
    setSelectedIndex
  );
  setHasUnsavedChanges(true);
  if (reportResult.isNew) {
    solidifyNewResult(reportResult);
  }
  const updatedReport = { ...selectedReport };
  if (updatedReport.resultDefinitions[0]) {
    updatedReport.resultDefinitions[0].columnDefinitions =
      reportResult.columnDefinitions;
  }
  setSelectedReport(updatedReport);
};

export const handleColumnOrderChange = (
  updatedColumns: ReportColumn[],
  setHasUnsavedChanges,
  selectedReport,
  setSelectedReport
) => {
  setHasUnsavedChanges(true);

  const updatedReport = { ...selectedReport };
  if (updatedReport.resultDefinitions[0]) {
    updatedReport.resultDefinitions[0].columnDefinitions = updatedColumns;
  }
  setSelectedReport(updatedReport);
};
