import { Observable } from 'rxjs';

const lanternUrl = (url: string): string =>
  process.env.REACT_APP_LANTERN_API_URL + url;

export const TenantSettings = (sessionKey) => {
  const requestMethod = { method: 'GET' };

  return new Observable((observer) => {
    fetch(lanternUrl(`/api/tenantsettings/${sessionKey}`), {
      ...requestMethod,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
    })
      .then((response) => {
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (!contentType || contentType.indexOf('application/json') === -1) {
            observer.next(response);
            observer.complete();
          } else {
            response.json().then((data) => {
              observer.next(data);
              observer.complete();
            });
          }
        } else {
          observer.error(new Error('Response not OK'));
          observer.complete();
        }
      })
      .catch((err) => observer.error(err));
  });
};
