import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person'
import { useUser } from '../redux/auth/auth-selectors';

const useStyles = makeStyles((theme: Theme) => ({
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '32px'
  },
  user: {
    paddingLeft: '6px',
  },
}));
export const TopBarUser: React.FC = () => {
  const user = useUser();
  const styles = useStyles();
  return (
    <div className={styles.userContainer}>
      <PersonIcon />
      <span className={styles.user}>{user && user.name}</span>
    </div>
  );
};
