import { map, Observable } from 'rxjs';
import { store } from '../index';
import { RequestResult } from '../models/request-result';
import { ReportsActions } from '../redux/reports-actions';
import { actionAuthLogout } from '../redux/auth/auth-actions';

export const LOCAL_STORAGE_KEY_SESSION_KEY = 'sessionKey';

export class FetchService {
  public static baseUrl = process.env.REACT_APP_API_URL;

  static getAccessToken = (): string | undefined => {
    const sessionKey = localStorage.getItem(LOCAL_STORAGE_KEY_SESSION_KEY);
    return sessionKey === null ? undefined : sessionKey;
  };

  static DoPost(url: string, data: any) {
    return this.DoRequest(url, 'POST', data);
  }

  static DoWrappedPost(url: string, data: any) {
    return this.DoRequest(url, 'POST', data).pipe(
      map((result) => result as RequestResult<any>),
      map((result) => {
        if (result.success) {
          return result.data;
        }
        ReportsActions.actionShowError(result.messages)(store.dispatch);
        return null;
      })
    );
  }

  static DoPostFile(url: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.DoRequest(url, 'POST', { body: formData }, true);
  }

  static DoGet(url: string) {
    return this.DoRequest(url, 'GET', null);
  }

  static DoWrappedGet(url: string) {
    return this.DoRequest(url, 'GET', null).pipe(
      map((result) => result as RequestResult<any>),
      map((result) => {
        if (result.success) {
          return result.data;
        }
        return null;
      })
    );
  }

  static DoDelete(url: string, data?: any) {
    return this.DoRequest(url, 'DELETE', data);
  }

  static DoRequest(
    url: string,
    method: string,
    data: any,
    isFileUpload: boolean = false
  ): Observable<any> {
    const accessToken = this.getAccessToken();
    const authHeader = accessToken
      ? { Authorization: `Bearer ${accessToken}` }
      : {};
    const requestMethod = { method: method };

    let requestBody = {};

    if (method !== 'GET') {
      if (!isFileUpload) {
        const json = JSON.stringify(data);
        requestBody = { body: json };
      } else {
        requestBody = data;
      }
    }

    return new Observable((observer) => {
      fetch(this.baseUrl + url, {
        ...requestMethod,
        headers: isFileUpload
          ? {
              pragma: 'no-cache',
              'Cache-Control': 'no-cache',
              Accept: `application/vnd.iman.v1.0.0+json, application/json, text/plain, */*`,
              ...authHeader,
            }
          : {
              'Content-Type': 'application/json; charset=utf-8',
              pragma: 'no-cache',
              'Cache-Control': 'no-cache',
              ...authHeader,
            },
        ...requestBody,
      })
        .then((response) => {
          if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (
              !contentType ||
              contentType.indexOf('application/json') === -1
            ) {
              observer.next(response);
              observer.complete();
            } else {
              response.json().then((data) => {
                observer.next(data);
                observer.complete();
              });
            }
          } else {
            if (response.status === 401) {
              store.dispatch(actionAuthLogout());
              observer.error('Logged out');
            } else {
              observer.next(response);
              observer.complete();
            }
          }
        })
        .catch((err) => observer.error(err));
    });
  }
}
