import { ListItem, ListItemText } from '@mui/material';
import {
  ReportFilter,
  ReportFilterInputType,
  ReportFilterOperator,
} from '../../models/report-details';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { generateUUID } from '../../utils/common-utils';

export const changeFilterSelection = (
  filter: ReportFilter,
  index: number,
  setSelectedFilter,
  setSelectedIndex
) => {
  setSelectedFilter(null);
  setSelectedIndex(index);
  setTimeout(() => {
    setSelectedFilter(filter);
  }, 10);
};
export const renderFilterItem = (
  filter: ReportFilter,
  index,
  setSelectedIndex,
  newFilters,
  selectedFilter,
  setSelectedFilter,
  setNewFilters,
  selectedReport,
  setHasUnsavedChanges
) => {
  return (
    <>
      <ListItem
        key={index}
        button
        sx={{
          width: '100%',
          marginBottom: '1px',
          backgroundColor: '#fff',
          paddingLeft: '40px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        onClick={() =>
          changeFilterSelection(
            filter,
            index,
            setSelectedFilter,
            setSelectedIndex
          )
        }>
        <div>
          <ListItemText primary={filter.displayName}></ListItemText>
        </div>

        <div>
          {filter === selectedFilter && (
            <DeleteIcon
              sx={{ color: 'rgba(0, 0, 0, 0.26)' }}
              onClick={(e) =>
                handleDeleteFilter(
                  e,
                  index,
                  newFilters,
                  selectedFilter,
                  setSelectedFilter,
                  setNewFilters,
                  selectedReport,
                  setHasUnsavedChanges
                )
              }
            />
          )}

          <KeyboardArrowRightIcon />
        </div>
      </ListItem>
    </>
  );
};
export const handleDeleteFilter = (
  event: any,
  index: number,
  newFilters,
  selectedFilter,
  setSelectedFilter,
  setNewFilters,
  selectedReport,
  setHasUnsavedChanges
) => {
  setTimeout(() => {
    deleteFilter(
      event,
      index,
      newFilters,
      selectedFilter,
      setSelectedFilter,
      setNewFilters,
      selectedReport,
      setHasUnsavedChanges
    );
  }, 10);
  setTimeout(() => {
    setSelectedFilter(null);
  }, 12);
};

export const deleteFilter = (
  event: any,
  index: number,
  newFilters,
  selectedFilter,
  setSelectedFilter,
  setNewFilters,
  selectedReport,
  setHasUnsavedChanges
) => {
  event.stopPropagation();
  if (index < 0) {
    if (newFilters.some((filter) => filter.uuid === selectedFilter.uuid)) {
      setSelectedFilter(null);
    }
    setNewFilters(
      newFilters.filter((filter) => filter.uuid !== selectedFilter.uuid)
    );
  } else {
    var removed = selectedReport.filterDefinitions.splice(index, 1);
    if (selectedFilter == removed[0]) {
      setSelectedFilter(null);
    }
  }
  setHasUnsavedChanges(true);
};

export const addNewFilter = (
  setNewFilters,
  newFilters,
  setSelectedFilter,
  setHasUnsavedChanges
) => {
  const filter: ReportFilter = {
    uuid: generateUUID(),
    clusionType: 'all',
    displayName: 'New filter',
    operator: ReportFilterOperator.Equals,
    reportInputType: ReportFilterInputType.MultiSelect,
    sqlColumn: '',
    filterOrder: 0,
  };
  setNewFilters(
    newFilters && newFilters.length ? [...newFilters, filter] : [filter]
  );
  setSelectedFilter(null);
  setTimeout(() => {
    setSelectedFilter(filter);
  }, 10);
  setHasUnsavedChanges(true);
};
