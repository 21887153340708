import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ReportsActions } from '../../redux/reports-actions';
import { useHistory } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import LockIcon from '@mui/icons-material/Lock';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const useStyles = makeStyles(() => ({
  header: {
    fontWeight: 700,
  },
  allReports: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  allReportsHeader: {
    backgroundColor: '#d9dde8',
    height: '50px',
    width: '100%',
    padding: '10px',
    fontWeight: 'bold',
    fontSize: '18px',
    '&.Mui-expanded': {
      minHeight: '50px',
    },
  },
  favouriteIcon: {
    color: '#385690',
  },
  searchContainer: {
    padding: '13px 10px',
    backgroundColor: '#fff',
  },
  buttonsRow: {
    display: 'flex',
    padding: '20px 0',
  },
  categoryContainer: {
    paddingBottom: '34px',
    width: '100%',
  },
  accordionSummary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  summaryRightElements: {
    display: 'flex',
    alignItems: 'center',
  },
  categoryReport: {
    display: 'flex',
    width: '100%',
    borderBottom: '2px solid #f0f0f0',
    padding: '10px',
  },
  AccordionDetails: {
    padding: '0',
  },
  reports: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  ReportGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingRight: '40px',
    paddingBottom: '50px',
    backgroundColor: '#fff',
  },
  sortableListContainer: {
    maxWidth: 400,
    margin: '30px 0',
  },
  ReportGroupHeaderContainer: {
    borderBottom: 'solid black 1px',
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '18px',
    padding: '0 6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reportContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    paddingLeft: '30px',
    padding: '9px 0',
    borderBottom: '2px solid #f0f0f0',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      '& $hoverDisplay': {
        display: 'block',
      },
    },
  },
  hoverDisplay: {
    display: 'none',
  },
  viewMore: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

type Props = {
  title?: string;
  favourites?: boolean;
  items?: any;
  reportList: any;
  expanded: any;
  setExpanded: any;
};
export const ReportCategory: React.FC<Props> = ({ title, items }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [showAll, setShowAll] = useState(false);
  const history = useHistory();
  useEffect(() => {
    ReportsActions.actionLoadFavorites()(dispatch);
  }, [dispatch]);
  const favoriteReportsList =
    items &&
    items.flatMap((reportGroup) =>
      reportGroup.reports.map((report) => ({
        ...report,
        groupCode: reportGroup.code,
        ...(reportGroup.isGroupEditAllowed
          ? { global: false }
          : { global: true }),
      }))
    );

  const displayedReports = showAll
    ? favoriteReportsList
    : favoriteReportsList && favoriteReportsList.slice(0, 5);
  return (
    <div className={styles.categoryContainer}>
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          className={styles.allReportsHeader}
          aria-controls='panel1d-content'
          id='panel1d-header'>
          <div className={styles.accordionSummary}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {title}
              <StarIcon
                style={{ marginLeft: '12px' }}
                className={styles.favouriteIcon}
              />
            </div>
            <div className={styles.summaryRightElements}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.AccordionDetails}>
          <div className={styles.reports}>
            {displayedReports &&
              displayedReports.map((r, index) => (
                <div
                  key={index}
                  onClick={() =>
                    history.push(`/reports/${r.groupCode}/${r.code}`)
                  }
                  className={styles.reportContainer}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={styles.hoverDisplay}></div>
                    <div>{r.label}</div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginRight: '10px',
                    }}>
                    {r.global ? (
                      <Tooltip
                        title={t(
                          'GLOBAL_REPORT_TOOLTIP',
                          'This is a global report and it can not be modified.'
                        )}>
                        <LockIcon style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
                      </Tooltip>
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </div>
                </div>
              ))}
            {showAll ? (
              <>
                {favoriteReportsList && favoriteReportsList.length > 5 && (
                  <Button
                    sx={{ color: 'grey' }}
                    onClick={() => setShowAll(false)}>
                    {t('SHOW_LESS', 'Vis mindre')}
                    <KeyboardArrowUpIcon />
                  </Button>
                )}
              </>
            ) : (
              <>
                {favoriteReportsList && favoriteReportsList.length > 5 && (
                  <Button
                    sx={{ color: 'grey' }}
                    onClick={() => setShowAll(true)}>
                    {t('VIEW_ALL', 'Vis alt')}
                    <KeyboardArrowDownIcon />
                  </Button>
                )}
              </>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
