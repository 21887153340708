import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';

import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ReportColumn } from '../../models/report-details';
import { BaseQueryColumnInfo } from '../../models/report-info';
import { CubitSwitchAdapter } from '../cubit-inputs/react-form-adapters/cubit-switch-adapter';
import { CubitTextFieldAdapter } from '../cubit-inputs/react-form-adapters/cubit-text-field-adapter';
import { CubitAutocompleteAdapter } from '../cubit-inputs/react-form-adapters/cubit-autocomplete-adapter';
import { ColumnText, processedColumns } from './config-helper';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: '100%',
  },
  iconButtonContainer: {
    width: '100%',
    borderBottom: 'solid #000 1px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formValueContainer: {
    display: 'flex',
    padding: '12px 0 0 24px',
  },
  formValueLabel: {
    minWidth: '200px',
    color: ' rgba(0, 0, 0, 0.55)',
  },
  dialogContainer: {
    height: '100%',
  },
  dialog: {
    padding: '16px',
  },
}));

const SelectedColumnForm: React.FC<
  InjectedFormProps<ReportColumn> & {
    potentialColumns: BaseQueryColumnInfo[];
    index: number;
    selectedColumn: any;
    setSelectedColumn: any;
    newColumn: any;
    setNewColumn: any;
    reportResult: any;
    setHasUnsavedChanges: any;
    open: boolean;
    baseQuery: string;
  }
> = (props) => {
  const {
    handleSubmit,
    form,
    potentialColumns,
    setSelectedColumn,
    selectedColumn,
    newColumn,
    baseQuery,
  } = props;
  const styles = useStyles();
  const [isOpen, setOpen] = useState(newColumn?.length ? true : false);
  const { t } = useTranslation();
  const columnText = ColumnText(t);
  const values = useSelector((state) =>
    getFormValues(form)(state)
  ) as ReportColumn;

  return (
    <>
      <div className={styles.iconButtonContainer}>
        <div>{values?.displayName}</div>
        <div>
          <IconButton onClick={() => setOpen(true)}>
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <div>
        <div className={styles.formValueContainer}>
          <div className={styles.formValueLabel}>
            {t('CUSTOM_DATA', 'Tilpasset data')}
          </div>
          <div>
            {values?.isAggregateColumn ? t('YES', 'Ja') : t('NO', 'Nei')}
          </div>
        </div>

        <div className={styles.formValueContainer}>
          <div className={styles.formValueLabel}>
            {values?.isAggregateColumn
              ? t('SQL_QUERY', 'SQL spørring')
              : t('SQL_COLUMN', 'SQL kolonne')}
          </div>

          {values?.isAggregateColumn && <div>{values.sqlColumn}</div>}
          {values?.isAggregateColumn === false && (
            <div>
              {!values?.selectColumn ? (
                <i>-{t('NOT_SELECTED', 'Ikke valgt')}-</i>
              ) : (
                columnText(values?.selectColumn)
              )}
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Dialog
          className={styles.dialog}
          maxWidth={'xs'}
          fullWidth={true}
          open={isOpen}
          onClose={() => setOpen(false)}>
          <DialogTitle>{t('EDIT_COLUMN', 'Endre kolonne')}</DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <div className={styles.dialogContainer}>
                <div className={styles.formControl}>
                  <Field
                    formcontrolprops={{ className: styles.formControl }}
                    name='displayName'
                    label={t('TITLE', 'Tittel')}
                    component={CubitTextFieldAdapter}
                  />
                </div>

                <Field
                  label={t('CUSTOM_DATA', 'Tilpasset data')}
                  component={CubitSwitchAdapter}
                  formcontrolprops={{ className: styles.formControl }}
                  name='isAggregateColumn'
                />
                {!values?.isAggregateColumn && (
                  <Field
                    type='select'
                    formcontrolprops={{ className: styles.formControl }}
                    name='selectColumn'
                    label={t('SQL_COLUMN', 'SQL kolonne')}
                    setSelectedColumn={setSelectedColumn}
                    selectedColumn={selectedColumn}
                    initialValue={values?.sqlColumn}
                    component={CubitAutocompleteAdapter}
                    options={processedColumns(baseQuery, potentialColumns, t)}
                  />
                )}

                {!!values && values?.isAggregateColumn && (
                  <Field
                    formcontrolprops={{ className: styles.formControl }}
                    name='sqlColumn'
                    label={t('SQL_QUERY', 'SQL spørring')}
                    component={CubitTextFieldAdapter}
                  />
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button variant={'text'} onClick={() => setOpen(false)}>
                {t('CANCEL', 'Avbryt')}
              </Button>
              <Button
                variant={'contained'}
                onClick={handleSubmit}
                type='submit'>
                {t('save.column', 'Lagre kolonne')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
};
export const SelectedColumnBlock = reduxForm<
  ReportColumn,
  {
    potentialColumns: BaseQueryColumnInfo[];
    baseQuery: string;
    index: number;
    selectedColumn: any;
    setSelectedColumn: any;
    newColumn: any;
    setNewColumn: any;
    reportResult: any;
    setHasUnsavedChanges: any;
    open: boolean;
  }
>({
  form: 'selectedColumnForm',
  enableReinitialize: false,
})(SelectedColumnForm as any);
