import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TopBar } from '../components/top-bar';
import { TopBarUser } from '../components/top-bar-user';
import { LanguagePicker } from '../components/language-picker';
import { LogoutButton } from '../components/logout-button';
import { useHistory } from 'react-router-dom';

export const ErrorPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <TopBar
        pageTitle='Error'
        leftElements={[]}
        rightElements={[
          <TopBarUser key='top-bar-user' />,
          <LanguagePicker key='language-picker' />,
          <LogoutButton key='logout-button' />,
        ]}></TopBar>
      <div className='pageContainer'>
        <div style={{ marginTop: '100px' }}>
          <h1>{t('ERROR_MESSAGE', 'An error has occurred')}</h1>
          <p>{t('ERROR_MESSAGE_DETAILS', '404 page does not exist')}</p>
          <Button
            variant='contained'
            color='primary'
            onClick={() => history.push(`/dashboard`)}>
            {t('RETURN_TO_DASHBOARD', 'Return to dashboard')}
          </Button>
        </div>
      </div>
    </>
  );
};
