import { Fragment, useState } from 'react';
import { FilterItem, FilterObject } from '../models/filter-object';
import { ReportFilterInfo } from '../models/report-filter';
import { MultiSelectFilter } from './filters/multi-select';
import { DateTimeRangeFilter } from './filters/datetime-range';
import { NumericRangeFilter } from './filters/numeric-range';
import { SelectFilter } from './filters/select';
import {
  ReportFilterInputType,
  ReportFilterOperator,
} from '../models/report-details';
import { CheckboxRadioFilter } from './filters/checkbox-radio';
import { ReportButton } from './dashboard/report-button';
import makeStyles from '@mui/styles/makeStyles';
import { Chip, Theme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme: Theme) => ({
  activeFilters: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '1px',
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: '0 16px',
    cursor: 'pointer',
  },
  filtersActions: {
    display: 'flex',
    marginTop: '1px',
    justifyContent: 'center',
    backgroundColor: '#fff',
    padding: '16px 0',
  },
  filters: {
    padding: '10px 32px',
    backgroundColor: '#fff',
  },
}));
export const Filters: React.FC<{
  filters: any;
  appliedFilters: FilterObject;
  applyFilters: (pendingFilters: FilterObject, isReload: boolean) => void;
  isLoading?: boolean;
  pendingFilters: FilterObject;
  setPendingFilters: (f: FilterObject) => void;
}> = (props) => {
  const [expanded, setExpanded] = useState(true);
  const styles = useStyles();
  const { t } = useTranslation();
  const renderFilter = (filter: ReportFilterInfo, key) => {
    switch (filter.reportInputType) {
      case ReportFilterInputType.Boolean:
        return (
          <CheckboxRadioFilter
            key={key}
            filter={filter}
            pendingFilters={props.pendingFilters}
            setPendingFilters={props.setPendingFilters}></CheckboxRadioFilter>
        );
      case ReportFilterInputType.MultiSelect:
        if (filter.options.length <= 1) {
          return <span key={key}></span>;
        }
        return (
          <MultiSelectFilter
            key={key}
            filter={filter}
            pendingFilters={props.pendingFilters}
            setPendingFilters={props.setPendingFilters}></MultiSelectFilter>
        );
      case ReportFilterInputType.SelectList:
        if (filter.options.length <= 1) {
          return <span key={key}></span>;
        }
        return (
          <SelectFilter
            key={key}
            filter={filter}
            pendingFilters={props.pendingFilters}
            setPendingFilters={props.setPendingFilters}></SelectFilter>
        );
      case ReportFilterInputType.DateTime:
      case ReportFilterInputType.Date:
        return (
          <DateTimeRangeFilter
            key={key}
            filter={filter}
            pendingFilters={props.pendingFilters}
            setPendingFilters={props.setPendingFilters}></DateTimeRangeFilter>
        );
      case ReportFilterInputType.Number:
      case ReportFilterInputType.Text:
        return (
          <NumericRangeFilter
            key={key}
            filter={filter}
            pendingFilters={props.pendingFilters}
            setPendingFilters={props.setPendingFilters}></NumericRangeFilter>
        );
    }
  };

  function clearFilter(e: any, filterItem: FilterItem) {
    const index = props.pendingFilters.appliedFilters.findIndex(
      (x) => x.filterId === filterItem.filterId
    );

    if (index !== -1) {
      props.pendingFilters.appliedFilters.splice(index, 1);
      props.applyFilters(props.pendingFilters, false);

      e.stopPropagation();
    }
  }

  const renderFilterSummary = (filterItem: FilterItem) => {
    const filter = props.filters.find(
      (x) => x.filterId === filterItem.filterId
    );
    if (filter.isHidden) {
      return <span></span>;
    }

    const getFilterValue = (itemValue: any) => {
      return (
        filter.options.find((x) => x.code === itemValue)?.value || itemValue
      );
    };

    switch (filter.reportInputType) {
      case ReportFilterInputType.Boolean:
        return (
          <div
            style={{
              margin: '0 10px',
            }}
            key={`summary_${filter.filterId}`}>
            <Chip
              sx={{ borderRadius: '5px' }}
              label={`${filter.displayName}: ${
                filterItem.values[0] === 'true' ? 'Ja' : 'Nei'
              }`}
              onDelete={(e) => clearFilter(e, filterItem)}
            />
          </div>
        );
      case ReportFilterInputType.MultiSelect:
        return (
          <div
            style={{
              margin: '0 10px',
            }}
            key={`summary_${filter.filterId}`}>
            <Chip
              sx={{ borderRadius: '5px' }}
              label={`${filter.displayName}:
                ${filterItem.values.map((x) => getFilterValue(x)).join(', ')}`}
              onDelete={(e) => clearFilter(e, filterItem)}
            />
          </div>
        );
      case ReportFilterInputType.SelectList:
        return (
          <div
            style={{
              margin: '0 10px',
            }}
            key={`summary_${filter.filterId}`}>
            <Chip
              sx={{ borderRadius: '5px' }}
              label={`${filter.displayName}: ${getFilterValue(
                filterItem.values[0]
              )}`}
              onDelete={(e) => clearFilter(e, filterItem)}
            />
          </div>
        );
      case ReportFilterInputType.DateTime:
      case ReportFilterInputType.Date:
        const fromDate =
          ('0' + filterItem.from.getDate()).slice(-2) +
          '.' +
          ('0' + (filterItem.from.getMonth() + 1)).slice(-2) +
          '.' +
          filterItem.from.getFullYear();
        const toDate =
          ('0' + filterItem.to.getDate()).slice(-2) +
          '.' +
          ('0' + (filterItem.to.getMonth() + 1)).slice(-2) +
          '.' +
          filterItem.to.getFullYear();
        if (filter.operator === ReportFilterOperator.GreaterThan) {
          return (
            <div
              style={{
                margin: '0 10px',
              }}
              key={`summary_${filter.filterId}`}>
              <Chip
                sx={{ borderRadius: '5px' }}
                label={`${filter.displayName}: fra ${fromDate}`}
                onDelete={(e) => clearFilter(e, filterItem)}
              />
            </div>
          );
        }
        if (filter.operator === ReportFilterOperator.LessThan) {
          return (
            <div
              style={{
                margin: '0 10px',
              }}
              key={`summary_${filter.filterId}`}>
              <Chip
                sx={{ borderRadius: '5px' }}
                label={`${filter.displayName}: til ${toDate}`}
                onDelete={(e) => clearFilter(e, filterItem)}
              />
            </div>
          );
        }
        if (filter.operator === ReportFilterOperator.InBetween) {
          return (
            <div
              style={{
                margin: '0 10px',
              }}
              key={`summary_${filter.filterId}`}>
              <Chip
                sx={{ borderRadius: '5px' }}
                label={`${filter.displayName}: fra ${fromDate} til ${toDate}`}
                onDelete={(e) => clearFilter(e, filterItem)}
              />
            </div>
          );
        }
        if (filter.operator === ReportFilterOperator.Equals) {
          return (
            <div
              style={{
                margin: '0 10px',
              }}
              key={`summary_${filter.filterId}`}>
              <Chip
                sx={{ borderRadius: '5px' }}
                label={`${filter.displayName}: ${fromDate}`}
                onDelete={(e) => clearFilter(e, filterItem)}
              />
            </div>
          );
        }
        return (
          <div key={`summary_${filter.filterId}`}>
            {filter.displayName}: {`${fromDate}`}{' '}
          </div>
        );

      case ReportFilterInputType.Number:
      case ReportFilterInputType.Text:
        if (filter.operator === ReportFilterOperator.GreaterThan) {
          return (
            <div
              style={{
                margin: '0 10px',
              }}
              key={`summary_${filter.filterId}`}>
              <Chip
                sx={{ borderRadius: '5px' }}
                label={`${filter.displayName}: fra ${filterItem.from}`}
                onDelete={(e) => clearFilter(e, filterItem)}
              />
            </div>
          );
        }
        if (filter.operator === ReportFilterOperator.Equals) {
          return (
            <div
              style={{
                margin: '0 10px',
              }}
              key={`summary_${filter.filterId}`}>
              <Chip
                sx={{ borderRadius: '5px' }}
                label={`${filter.displayName}: ${filterItem.from}`}
                onDelete={(e) => clearFilter(e, filterItem)}
              />
            </div>
          );
        }
        if (filter.operator === ReportFilterOperator.LessThan) {
          return (
            <div
              style={{
                margin: '0 10px',
              }}
              key={`summary_${filter.filterId}`}>
              <Chip
                sx={{ borderRadius: '5px' }}
                label={`${filter.displayName}: til ${filterItem.to}`}
                onDelete={(e) => clearFilter(e, filterItem)}
              />
            </div>
          );
        }
        if (filter.operator === ReportFilterOperator.InBetween) {
          return (
            <div
              style={{
                margin: '0 10px',
              }}
              key={`summary_${filter.filterId}`}>
              <Chip
                sx={{ borderRadius: '5px' }}
                label={`${filter.displayName}: fra ${filterItem.from} til ${filterItem.to}`}
                onDelete={(e) => clearFilter(e, filterItem)}
              />
            </div>
          );
        }
        return (
          <div
            style={{
              margin: '0 10px',
            }}
            key={`summary_${filter.filterId}`}>
            <Chip
              sx={{ borderRadius: '5px' }}
              label={`${filter.displayName}: ${
                filterItem.from || filterItem.to
              }`}
              onDelete={(e) => clearFilter(e, filterItem)}
            />
          </div>
        );
    }
  };

  return (
    <div key='filters' className='filters'>
      <div
        className={styles.activeFilters}
        onClick={() => setExpanded(!expanded)}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ verticalAlign: 'top', padding: '20px 0px' }}>
            {t('FILTERS', 'Filter')}:
          </div>
          <div
            style={{
              padding: '0',
              paddingLeft: '20px',
              fontSize: '14px',
              fontWeight: 'normal',
              display: 'flex',
            }}>
            {props.appliedFilters.appliedFilters
              .filter((x) => x.values.length || !!x.from || !!x.to)
              .map((filter) => renderFilterSummary(filter))}
          </div>
        </div>
        <div>
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
      </div>

      {expanded && (
        <Fragment>
          {props.filters && (
            <div className={styles.filters}>
              {props.filters.map((filter, index) => (
                <Fragment key={index}>
                  {renderFilter(filter, filter.key)}
                </Fragment>
              ))}
            </div>
          )}

          <div className={styles.filtersActions}>
            <ReportButton
              primary
              onClick={() => {
                props.applyFilters(props.pendingFilters, true);
                setExpanded(false);
              }}
              title={t('GENERATE_PREVIEW', 'GENERER FORHÅNDSVISNING')}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};
