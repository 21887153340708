import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AddReport } from './dashboard/add-report';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  group: any;
  isOpen: any;
  handleClose: any;
  setBusyGroupSub: any;
};

export const NewGroupSnackbar: React.FC<Props> = ({
  group,
  isOpen,
  handleClose,
  setBusyGroupSub,
}) => {
  const [open, setOpen] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(3000);
  const { t } = useTranslation();

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={30000}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        padding: '12px',
        borderRadius: '5px',
      }}
      message={t('NEW_GROUP_CREATED', 'Ny gruppe er opprettet')}>
      {/* <Alert severity='success' onClose={handleClose}> */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          {t(
            'NEW_GROUP_CREATED',
            `Rapportgruppe '${group && group.displayName}' ble opprettet`
          )}
        </div>
        <div onClick={()=>setAutoHideDuration(null)}>
          <AddReport setBusyGroupSub={setBusyGroupSub} setSnackbarOpen={() => setOpen(false)} group={group} />
        </div>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon sx={{ color: '#fff' }} />
        </IconButton>
      </div>
      {/* </Alert> */}
    </Snackbar>
  );
};
