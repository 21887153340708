import { useEffect, useState } from 'react';
import { FilterObject } from '../../models/filter-object';
import {
  ReportFilterInputType,
  ReportFilterOperator,
} from '../../models/report-details';
import { ReportFilterInfo } from '../../models/report-filter';
import { Slider, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  formGroup: {
    backgroundColor: '#fff',
    padding: '10px 0',
    justifyContent: 'space-between',
    '&.MuiFormGroup-root': {
      display: 'flex',
      flexDirection: 'row',
      padding: '10px 0',
    },
  },

  formGroupLabel: {
    paddingLeft: '6px',
    fontWeight: 'bold',
    display: 'flex',
    width: '200px',
    minWidth: '200px',
  },
}));
export const NumericRangeFilter = (props: {
  filter: ReportFilterInfo;
  pendingFilters: FilterObject;
  setPendingFilters: (f: FilterObject) => void;
}) => {
  let filter = props.filter;
  let pendingFilters = props.pendingFilters;
  const [fromValue, setFrom] = useState(parseInt(filter.defaultValueMin));
  const [toValue, setTo] = useState(parseInt(filter.defaultValueMax));
  const styles = useStyles();

  useEffect(() => {
    if (filter.defaultValueMin) {
      handleFromChange(parseInt(filter.defaultValueMin));
    }
    if (filter.defaultValueMax) {
      handleToChange(parseInt(filter.defaultValueMax));
    }
  });

  function handleFromChange(val: any) {
    setFrom(val);
    var filterItem = pendingFilters.appliedFilters.find(
      (x) => x.filterId === filter.filterId
    );

    if (!filterItem) {
      filterItem = {
        filterId: filter.filterId,
        values: [],
      };
      pendingFilters.appliedFilters.push(filterItem);
    }
    filterItem.from = val.toString();
    if (filter.operator === ReportFilterOperator.GreaterThan) {
      filterItem.to = null;
    }
  }

  function handleToChange(val: any) {
    setTo(val);
    var filterItem = pendingFilters.appliedFilters.find(
      (x) => x.filterId === filter.filterId
    );

    if (!filterItem) {
      filterItem = {
        filterId: filter.filterId,
        values: [],
      };
      pendingFilters.appliedFilters.push(filterItem);
    }
    filterItem.to = val.toString();
    if (filter.operator === ReportFilterOperator.LessThan) {
      filterItem.from = null;
    }
  }
  if (filter.isHidden) {
    return <span></span>;
  }

  return (
    <div className={styles.formGroup}>
      <div style={{ display: 'flex', width: '100%' }}>
        <div className={styles.formGroupLabel}>{filter.displayName}</div>

        {filter.operator === ReportFilterOperator.InBetween && (
          <>
            <TextField
              label='Fra'
              variant='outlined'
              type='number'
              onChange={(e) => handleFromChange(parseInt(e.target.value))}
              inputProps={{
                min: parseInt(filter.defaultValueMin),
                max: parseInt(filter.defaultValueMax),
                step: 1,
              }}
              value={fromValue}
            />
            {parseInt(filter.defaultValueMax) < 13 ? (
              <Slider
                sx={{ width: '50%', m: '0 32px' }}
                value={[fromValue, toValue]}
                step={1}
                marks={[
                  { value: 1, label: '1' },
                  { value: 2, label: '2' },
                  { value: 3, label: '3' },
                  { value: 4, label: '4' },
                  { value: 5, label: '5' },
                  { value: 6, label: '6' },
                  { value: 7, label: '7' },
                  { value: 8, label: '8' },
                  { value: 9, label: '9' },
                  { value: 10, label: '10' },
                  { value: 11, label: '11' },
                  { value: 12, label: '12' },
                ]}
                onChange={(e, values) => {
                  setFrom(values[0]);
                  setTo(values[1]);
                }}
                onChangeCommitted={(e, values) => {
                  handleFromChange(values[0]);
                  handleToChange(values[1]);
                }}
                min={parseInt(filter.defaultValueMin)}
                max={parseInt(filter.defaultValueMax)}
              />
            ) : (
              <div style={{ padding: '0 20px' }}></div>
            )}

            <TextField
              label='Til'
              variant='outlined'
              type='number'
              inputProps={{
                min: parseInt(filter.defaultValueMin),
                max: parseInt(filter.defaultValueMax),
                step: 1,
              }}
              onChange={(e) => handleToChange(parseInt(e.target.value))}
              value={toValue}
            />
          </>
        )}
        {filter.operator === ReportFilterOperator.GreaterThan &&
          filter.reportInputType === ReportFilterInputType.Number && (
            <TextField
              label='Fra'
              sx={{ maxWidth: '100px' }}
              variant='outlined'
              type='number'
              onChange={(e) => handleFromChange(e.target.value)}
              defaultValue={parseInt(filter.defaultValueMin)}
            />
          )}
        {filter.operator === ReportFilterOperator.Equals &&
          filter.reportInputType === ReportFilterInputType.Number && (
            <TextField
              sx={{ maxWidth: '100px' }}
              variant='outlined'
              type='number'
              onChange={(e) => handleFromChange(e.target.value)}
              defaultValue={parseInt(filter.defaultValueMin)}
            />
          )}
        {filter.operator === ReportFilterOperator.LessThan &&
          filter.reportInputType === ReportFilterInputType.Number && (
            <TextField
              sx={{ maxWidth: '100px' }}
              label='Til'
              variant='outlined'
              type='number'
              onChange={(e) => handleToChange(e.target.value)}
              defaultValue={parseInt(filter.defaultValueMax)}
            />
          )}
        {filter.operator === ReportFilterOperator.Equals &&
          filter.reportInputType === ReportFilterInputType.Text && (
            <TextField
              variant='outlined'
              type='text'
              onChange={(e) => handleFromChange(e.target.value)}
              defaultValue={filter.defaultValueMin}
            />
          )}
      </div>
    </div>
  );
};
