import { combineEpics } from 'redux-observable'
import { catchError, filter, flatMap, map } from 'rxjs/operators'
import { AnyAction } from 'redux'
import {
    ACTION_AUTH_INIT,
    ACTION_AUTH_LOGIN,
    ACTION_AUTH_LOGIN_SUCCESS,
    ACTION_AUTH_LOGOUT,
    actionAuthInitialized,
    actionAuthLoginError,
    actionAuthLoginSuccess,
} from './auth-actions'
import { EMPTY, Observable, of } from 'rxjs'
import { getSessionKey, removeSessionKey, setSessionKey, setSessionData, removeSessionData, getSessionData } from '../../utils'
import AuthClient from '../../clients/AuthClient'
import { history } from '../../index';

export const authLoginEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_LOGIN),
        flatMap(
            (action): Observable<AnyAction> =>
                AuthClient.login(action.request).pipe(
                    map(data => {
                        setSessionKey(data.session.sessionKey);
                        setSessionData(data);
                        return actionAuthLoginSuccess(data);
                    }),
                    catchError(error => of(actionAuthLoginError())),
                ),
        ),
    )

export const authLoginSuccessEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_LOGIN_SUCCESS),
        flatMap(
            (action): Observable<AnyAction> => {
                if (window.location.pathname === '/login') {
                    history.push('/');
                }
                return EMPTY;
            }               
        ),
    )

const clearSession = () => {
    removeSessionKey();
    removeSessionData();
    
};

export const authLogoutEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_LOGOUT),
        flatMap(
            (action): Observable<AnyAction> => {
                clearSession();
                history.push('/');
                window.location.reload();
                return EMPTY
            },
        ),
    )

export const authInitEpic = (action$: Observable<AnyAction>) =>
    action$.pipe(
        filter((action: AnyAction) => action.type === ACTION_AUTH_INIT),
        flatMap(
            (): Observable<AnyAction> => {
                const sessionKey = getSessionKey();
                const sessionData = getSessionData();
                if (sessionKey === undefined || sessionData === undefined) {
                    clearSession();
                    return of(actionAuthInitialized())
                } else {
                    try {
                        return of(actionAuthLoginSuccess(sessionData));
                    } catch(error) {
                        clearSession();
                        throw error;
                    }
                }
            },
        ),
    )

export const authEpics = combineEpics(authInitEpic, authLoginEpic, authLoginSuccessEpic, authLogoutEpic)
