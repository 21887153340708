import { OrderDirection } from './cubit-table.types'

export const ACTION_CUBIT_TABLE_ORDER = 'ACTION_CUBIT_TABLE_ORDER'
export const ACTION_CUBIT_TABLE_SELECT = 'ACTION_CUBIT_TABLE_SELECT'
export const ACTION_CUBIT_TABLE_CLEAR_SELECTION = 'ACTION_CUBIT_TABLE_CLEAR_SELECTION'

export const actionCubitTableOrder = (tableName: string, orderBy: string, orderDirection: OrderDirection) => ({
    type: ACTION_CUBIT_TABLE_ORDER,
    tableName,
    orderBy,
    orderDirection,
})

export const actionCubitTableSelect = (tableName: string, selection: any[]) => ({
    type: ACTION_CUBIT_TABLE_SELECT,
    tableName,
    selection,
})

export const actionCubitTableClearSelection = (tableName: string) => ({
    type: ACTION_CUBIT_TABLE_CLEAR_SELECTION,
    tableName,
})
