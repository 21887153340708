import { ReactComponent as NbFlag } from './svg/no.svg';
import { ReactComponent as EnFlag } from './svg/gb.svg';
import React from 'react';

type Props = {
  country: string;
  size?: number;
};
export const Flag: React.FC<Props> = ({ country, size = 18 }) => {
  let flagSvg;
  switch (country) {
    case 'en': {
      flagSvg = <EnFlag />;
      break;
    }
    case 'nb': {
      flagSvg = <NbFlag />;
      break;
    }
    default: {
      flagSvg = <NbFlag />;
    }
  }
  return <div style={{ width: size + 'px' }}>{flagSvg}</div>;
};
