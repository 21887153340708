import { Button, SvgIconTypeMap, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from '../../models/reports-button';
const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    height: '100%',
  },
  reportButtonPrimary: {
    color: '#333',
    borderRadius: '5px',
    border: 'solid 1px #333',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
  },
  reportButtonSecondary: {
    color: '#fff',
    backgroundColor: '#3f558c',
    border: 'solid 1px #fff',
    display: 'flex',
    alignItems: 'center',
    '&:hover':{
      backgroundColor: '#3f558c'
    }
  },
  reportButtonTopBar: {
    color: '#fff',
    backgroundColor: '#3f558c',
    border: 'solid 1px #fff',
    display: 'flex',
    alignItems: 'center',
  },

}));

export const ReportButton: React.FC<ButtonProps> = ({ startIcon, endIcon, title, primary, onClick }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <>
      <div className={styles.buttonContainer}>
        <Button
          className={
            primary ? styles.reportButtonPrimary : styles.reportButtonSecondary
          }
          variant='outlined'
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
          >
          {title}
        </Button>
      </div>
    </>
  );
};
