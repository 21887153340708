import React from 'react'
import FormControl from '@mui/material/FormControl'
import { InputBaseComponentProps } from '@mui/material/InputBase'
import FormGroup from '@mui/material/FormGroup'
import Switch from '@mui/material/Switch'
import { InputType } from '../input-type.enum'
import { FormControlLabel } from '@mui/material'

export type CubitSwitchAdapterProps = { type: InputType.Switch }

const CubitSwitchAdapter: React.FC<CubitSwitchAdapterProps> = (props: any) => {
    const {
        input: { name, value, onChange, ...restInput },
        options,
        formcontrolprops,
        meta,
        label,
        ...rest
    } = props

    const textFiledProps = (restInput as unknown) as InputBaseComponentProps

    const val = value || false

    return (
        <FormControl variant="standard" {...formcontrolprops}>
            <FormGroup row>
                <FormControlLabel control={
                    <Switch
                        {...rest}
                        name={name}
                        onChange={(event: any) => onChange(event.target.checked)}
                        checked={val}
                        value={val}
                        inputProps={textFiledProps}
                    />
                } label={label} />
                
            </FormGroup>
        </FormControl>
    );
}

export { CubitSwitchAdapter }
