import React from 'react';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import { InputType } from '../input-type.enum';
import { InputLabel, Divider, Chip } from '@mui/material';
import { SelectOptionType } from '../cubit-form-field.types';

export type CubitSelectAdapterProps = { type: InputType.Select };

const CubitSelectAdapter: React.FC<any> = (props: any) => {
  const {
    input: { name, value, onChange, onFocus, onBlur },
    defaultValue,
    disabled,
    options,
    valueisobject = true,
    label,
    formcontrolprops,
    meta,
    multiple,
    singleValue,
    ...rest
  } = props;

  const valueRendererProps = {
    selectedValue: value,
    options,
    onChange,
    disabled,
    singleValue,
  };
  const valueRenderer = multiple
    ? valueisobject
      ? renderSelectedMultipleObjects
      : renderSelectedMultipleValues
    : valueisobject
    ? renderSelectedObject
    : renderSelectedValue;

  return (
    <FormControl variant='standard' {...formcontrolprops}>
      <FormGroup row>
        {label ? <InputLabel>{label}</InputLabel> : null}
        <Select
          variant='standard'
          {...rest}
          name={name}
          defaultValue={defaultValue}
          value={value}
          multiple={multiple}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          renderValue={() => valueRenderer(valueRendererProps)}
          style={{ width: '100%' }}>
          {options.map((option: any, index: number) =>
            option.type === SelectOptionType.Title ? (
              <li key={index} style={{ padding: '8px', cursor: 'default' }}>
                {option.label}
              </li>
            ) : option.type === SelectOptionType.Divider ? (
              <Divider key={index}></Divider>
            ) : (
              <MenuItem
                key={index}
                value={valueisobject ? option : option.value}
                style={option.style}>
                {option.label}
              </MenuItem>
            )
          )}
        </Select>
      </FormGroup>
    </FormControl>
  );
};

export { CubitSelectAdapter };

type ValueRendererProps = {
  selectedValue: any;
  options: any;
  onChange: (value: any) => void;
  disabled: boolean;
  singleValue: any;
};

type ChipsRendererProps = {
  selectedValues: any;
  selectedValuesLabels: string;
  onDelete: (valueToDelete: any) => void;
  disabled: boolean;
};

type ChipRendererProps = {
  index: number;
  label: string;
  value: any;
  selectedValues: any;
  onDelete: (valueToDelete: any) => void;
  disabled: boolean;
};

const renderSelectedValue = (props: ValueRendererProps) => {
  const option = props.options.filter(
    (o: any) => o.value === props.selectedValue
  )[0];
  return option ? option.label : '';
};

const renderSelectedObject = (props: ValueRendererProps) =>
  props.singleValue ? props.singleValue.label : props.selectedValue.label;

const renderSelectedMultipleValues = (props: ValueRendererProps) => {
  const { selectedValue: selectedValues, onChange, disabled } = props;
  const onDelete = (valueToDelete: any) =>
    onChange(selectedValues.filter((v: any) => v !== valueToDelete));
  const selectedValuesLabels = selectedValues.map((val: any) => {
    const option = props.options.find((o: any) => o.value === val);
    return option.label;
  });

  return renderChips({
    selectedValues,
    selectedValuesLabels,
    onDelete,
    disabled,
  });
};

const renderSelectedMultipleObjects = (props: ValueRendererProps) => {
  const { selectedValue: selectedValues, onChange, disabled } = props;
  const selectedValuesLabels = selectedValues.map((v: any) => v.label);
  const onDelete = (valueToDelete: any) =>
    onChange(
      selectedValues.filter((v: any) => v.value !== valueToDelete.value)
    );

  return renderChips({
    selectedValues,
    selectedValuesLabels,
    onDelete,
    disabled,
  });
};

const renderChips = (props: ChipsRendererProps) => {
  const { selectedValues, selectedValuesLabels, onDelete, disabled } = props;
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {selectedValues.map((value: any, index: number) =>
        renderChip({
          index,
          label: selectedValuesLabels[index],
          value,
          selectedValues,
          onDelete,
          disabled,
        })
      )}
    </div>
  );
};

const renderChip = (props: ChipRendererProps) => (
  <Chip
    onClick={props.disabled ? undefined : (e) => e.stopPropagation()}
    key={props.index}
    label={props.label}
    onDelete={props.disabled ? undefined : () => props.onDelete(props.value)}
    style={{ margin: '0 6px 6px 0' }}
    disabled={props.disabled}
  />
);
