import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import * as React from 'react';
import { Button, Grid, Theme, Paper, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { CubitTextFieldAdapter } from '../components/cubit-inputs/react-form-adapters/cubit-text-field-adapter';
import { useTranslation } from 'react-i18next';
import { LoginRequest } from '../models/login-request';
import { actionAuthLogin } from '../redux/auth/auth-actions';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../redux/auth/auth-selectors';

type FormProps = {
  handleSubmit: any;
};
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: `radial-gradient(circle at top, ${
      useTheme().palette.primary.main
    } 35%, #0c7282 150%)`,
    backgroundColor: useTheme().palette.primary.main,
    width: '100%',
    height: '100%',
    minHeight: '100%',
    overflow: 'auto',
  },
  mainGrid: {
    width: '100%',
    height: '100%',
    minHeight: '100%',
    padding: useTheme().spacing(2),
  },

  welcomeTo: {
    color: useTheme().palette.primary.contrastText,
    fontSize: useTheme().typography.h4.fontSize,
    fontWeight: 300,
    textAlign: 'center',
    [useTheme().breakpoints.down('lg')]: {
      fontSize: useTheme().typography.h5.fontSize,
    },
  },
  appName: {
    color: useTheme().palette.primary.contrastText,
    fontSize: useTheme().typography.h3.fontSize,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    textAlign: 'center',
    [useTheme().breakpoints.down('lg')]: {
      fontSize: useTheme().typography.h4.fontSize,
    },
  },

  loginContainer: {
    width: '100%',
    maxWidth: '444px',
    '& .MuiFormControl-root': {
      width: '100%',
    },
    padding: useTheme().spacing(0, 4, 4, 4),
    marginTop: useTheme().spacing(6),
    [useTheme().breakpoints.down('lg')]: {
      padding: useTheme().spacing(0, 2, 2, 2),
    },
  },

  loginTitle: {
    color: useTheme().palette.primary.main,
    fontSize: useTheme().typography.h3.fontSize,
    fontWeight: 300,
    [useTheme().breakpoints.down('lg')]: {
      fontSize: useTheme().typography.h4.fontSize,
    },
  },

  loginDivider: {
    margin: useTheme().spacing(0, -4),
    [useTheme().breakpoints.down('lg')]: {
      margin: useTheme().spacing(0, -2),
    },
  },

  filledInputRoot: {
    width: '100%',

    '& .MuiInputBase-root': {
      borderRadius: 0,
      border: `1px solid ${grey[400]}`,
      width: '100%',
    },
  },

  loginButtonRoot: {
    width: '100%',
    fontSize: useTheme().typography.h5.fontSize,
    fontWeight: 300,
  },
  centerAlignFlexItem: {
    margin: 'auto',
  },
  errorMessage: {
    color: 'red',
  },
}));

const Form = (props: FormProps) => {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const styles = useStyles();
  const auth = useAuth();

  return (
    <div className={styles.container}>
      <Grid container className={styles.mainGrid}>
        <Grid item className={styles.centerAlignFlexItem}>
          <Grid container justifyContent='center' alignContent='center'>
            <Grid item xs={12} className={styles.welcomeTo}>
              {t('WELCOME_TO', 'Welcome to')}
            </Grid>
            <Grid item xs={12} className={styles.appName}>
              {t('CUBIT_REPORTING', 'Cubit reporting')}
            </Grid>
            <Grid item>
              <Paper className={styles.loginContainer} elevation={20}>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent='center'
                    alignContent='center'>
                    <Grid item className={styles.loginTitle}>
                      {t('LOGIN', 'Login')}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={4}
                    justifyContent='center'
                    alignContent='center'>
                    <Grid item xs={12}>
                      <Divider className={styles.loginDivider}></Divider>
                    </Grid>
                    {'error' in auth && (
                      <Grid
                        container
                        style={{ padding: '32px 0 0 32px' }}
                        spacing={2}
                        justifyContent='center'
                        alignContent='center'>
                        <Grid item className={styles.errorMessage}>
                          {t(
                            'LOG_IN_ERROR',
                            'Failed to log in. Please try again.'
                          )}
                        </Grid>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name='email'
                        label={t('EMAIL', 'Email')}
                        variant='filled'
                        autoFocus={true}
                        classes={{ root: styles.filledInputRoot }}
                        component={CubitTextFieldAdapter}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name='password'
                        label={t('PASSWORD', 'Password')}
                        variant='filled'
                        classes={{ root: styles.filledInputRoot }}
                        component={CubitTextFieldAdapter}
                        type='password'
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        color='primary'
                        type='submit'
                        classes={{ root: styles.loginButtonRoot }}>
                        {t('LOGIN', 'Login')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const LoginForm = reduxForm({
  form: 'LoginForm',
})(Form);

export const LoginPage: React.FC = () => {
  const dispatch = useDispatch();

  const onSubmit = (data: LoginRequest) => dispatch(actionAuthLogin(data));
  return <LoginForm onSubmit={onSubmit} />;
};
