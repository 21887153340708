import { useTranslation } from 'react-i18next';
import { Logout } from '@mui/icons-material';
import { actionAuthLogout } from '../redux/auth/auth-actions';
import { useDispatch } from 'react-redux';
import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: '#fff',
    textTransform: 'none',
    padding: '0'
  },
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  rightElements: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
export const LogoutButton: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useStyles();

  const logout = () => dispatch(actionAuthLogout());

  return (
    <Button className={styles.button} startIcon={<Logout />} onClick={logout}>
      {t('LOGOUT', 'Logg ut')}
    </Button>
  );
};
