import React from 'react'

import styles from './cubit-checkbox.module.css'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'

type CubitCheckboxProps = {
    checked: boolean
    indeterminate?: boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onClick?: (e: React.MouseEvent) => void
}

export const CubitCheckbox: React.FC<CubitCheckboxProps> = props => {
    const { checked, indeterminate, onChange, onClick } = { ...props }

    const renderCheckboxIcon = (isChecked: boolean, isIndeterminate: boolean | undefined): any => {
        return isIndeterminate ? (
            <IndeterminateCheckBoxIcon />
        ) : isChecked ? (
            <CheckBoxIcon className={styles.checkboxSelected} />
        ) : (
            <CheckBoxOutlineBlankIcon />
        )
    }

    return (
        <div className={styles.checkbox}>
            {renderCheckboxIcon(checked, indeterminate)}
            <input
                checked={checked}
                className={styles.checkboxInput}
                onChange={onChange}
                onClick={onClick}
                type="checkbox"
            />
        </div>
    )
}
