import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterObject } from '../../models/filter-object';
import {
  ReportFilterInfo,
  ReportFilterOption,
} from '../../models/report-filter';
import makeStyles from '@mui/styles/makeStyles';
import { Checkbox, FormControlLabel, Theme } from '@mui/material';
const useStyles = makeStyles((theme: Theme) => ({
  formGroup: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    backgroundColor: '#fff',
    width: '100%',
  },

  formGroupLabel: {
    paddingLeft: '6px',
    fontWeight: 'bold',
    display: 'flex',
    width: '200px',
  },
  checkboxLabel: {
    paddingLeft: '6px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#3f558c',
    },
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
export const CheckboxRadioFilter = (props: {
  filter: ReportFilterInfo;
  pendingFilters: FilterObject;
  setPendingFilters: (f: FilterObject) => void;
}) => {
  const { filter, pendingFilters, setPendingFilters } = props;
  const { t } = useTranslation();
  const styles = useStyles();

  useEffect(() => {
    if (filter.defaultValueMin && filter.defaultValueMin !== 'unset') {
      onChange(filter.defaultValueMin.toString(), true);
    }
  }, []);

  const onChange = (code: string, checked: boolean) => {
    if (!checked) {
      return;
    }
    var filterItem = pendingFilters.appliedFilters.find(
      (x) => x.filterId === filter.filterId
    );

    if (filterItem && code === 'unset') {
      pendingFilters.appliedFilters.splice(
        pendingFilters.appliedFilters.indexOf(filterItem),
        1
      );
      setPendingFilters({ ...pendingFilters });
      return;
    }

    if (!filterItem) {
      filterItem = {
        filterId: filter.filterId,
        values: [],
      };
      pendingFilters.appliedFilters.push(filterItem);
    }

    filterItem.values = [code];
    setPendingFilters({ ...pendingFilters });
  };

  const options = [
    {
      code: 'unset',
      value: 'Alle',
      label: t('all', 'Alle'),

      preSelected: true,
    },
    {
      code: 'true',
      value: 'Ja',
      label: t('YES', 'Ja'),

      preSelected: false,
    },
    {
      code: 'false',
      value: 'Nei',
      label: t('NO', 'Nei'),

      preSelected: false,
    },
  ];

  var filterItem = pendingFilters.appliedFilters.find(
    (x) => x.filterId === filter.filterId
  );

  const isChecked = (option: ReportFilterOption): boolean => {
    return (
      (!filterItem && option.code === 'unset') ||
      (!!filterItem && filterItem.values.includes(option.code))
    );
  };

  if (filter.isHidden) {
    return <span></span>;
  }

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupLabel}>{filter.displayName}</div>
      <div className={styles.options}>
        {options.map((option) => (
          <FormControlLabel
            className={styles.checkboxLabel}
            key={option.code}
            control={
              <Checkbox
                className={styles.checkbox}
                checked={isChecked(option)}
                onChange={(event) =>
                  onChange(option.code, event.target.checked)
                }
                value={option.value}
              />
            }
            label={option.label}
          />
        ))}
      </div>
    </div>
  );
};
