import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  compose,
} from 'redux';
import {
  cubitTableReducer,
  CubitTableState,
} from '../components/cubit-table/cubit-table-reducer';
import { FormStateMap, reducer as formReducer } from 'redux-form';
import { ReportsState } from '../models/reports-state';
import { reportsReducer } from './reports-reducer';
import { authReducer, AuthState } from './auth/auth-reducer';
import { createEpicMiddleware } from 'redux-observable';
import { authEpics } from './auth/auth-epics';
import {
  DashboardState,
  dashboardReducer,
} from './dashboard/dashboard-reducer';

export type AppState = {
  reports: ReportsState;
  table: CubitTableState;
  form: FormStateMap;
  auth: AuthState;
  dashboard: DashboardState;
};

const mainReducer = combineReducers<AppState>({
  reports: reportsReducer,
  table: cubitTableReducer,
  form: formReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
});

const rootReducer = (state: AppState, action: AnyAction) => {
  console.log('STATE', state, action);

  return mainReducer(state, action);
};

const initialState = {};

export const createAppStore = () => {
  // @ts-ignore
  const composeEnhancers =
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const epicMiddleware = createEpicMiddleware();
  // @ts-ignore
  const store = createStore(
    rootReducer,
    // @ts-ignore
    initialState,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(authEpics);
  return store;
};
