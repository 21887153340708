import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage } from "../models/request-result";
import { AppState } from "../redux/app-store";
import { ReportsActions } from "../redux/reports-actions";

export const ErrorSnackbar: React.FC = () => {
  const errorMessages = useSelector((state: AppState) => state.reports.errorMessages);
  const dispatch = useDispatch();

  const closeError = (error: ErrorMessage) => {
    const errors = [...errorMessages];
    errors.splice(errors.indexOf(error), 1);
    ReportsActions.actionShowError(errors)(dispatch);
  }

  return (
    <Snackbar open={errorMessages && errorMessages.length > 0}>
      <div>
        {errorMessages && errorMessages.map((error, index) =>
          <Alert key={index} severity="error" onClose={() => closeError(error)}>{error.header}</Alert>
        )}
      </div>
    </Snackbar>
  )
}