export const ValidatorRequired = (value: any, message: string) => {
    let valueExists = value !== undefined && value !== null

    if (valueExists && Array.isArray(value)) {
        valueExists = value.length !== 0
    }

    return valueExists ? undefined : message
}

export const ValidatorChecked = (value: any, message: string) => {
    const valid = value === true
    return valid ? undefined : message
}

const email_re =
    /^(([^<>()[].,;:\s@"]+(\.[^<>()[].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export const ValidatorEmail = (value: any, message: string) => {
    const valid = email_re.test(String(value).toLowerCase())
    return valid ? undefined : message
}

const phone_re = /^(0047|\+47|47)?[2-9]\d{7}$/
export const ValidatorPhone = (value: any, message: string) => {
    const valid = phone_re.test(String(value).toLowerCase())
    return valid ? undefined : message
}
