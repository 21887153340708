import {
  ReportFilterInputType,
  ReportFilterOperator,
} from '../../models/report-details';
import { BaseQueryColumnInfo } from '../../models/report-info';
import { CubitCheckboxAdapter } from '../cubit-inputs/react-form-adapters/cubit-checkbox-adapter';
import CubitDatePickerAdapter from '../cubit-inputs/react-form-adapters/cubit-date-picker-adapter';
import CubitDateTimePickerAdapter from '../cubit-inputs/react-form-adapters/cubit-date-time-picker-adapter';
import { CubitNumericFieldAdapter } from '../cubit-inputs/react-form-adapters/cubit-numeric-field-adapter';
import { CubitTextFieldAdapter } from '../cubit-inputs/react-form-adapters/cubit-text-field-adapter';

export const ClusionTypeItems = (t) => [
  {
    label: t('include_all', 'Include all'),
    value: 'all',
  },
  {
    label: t('include_selected', 'Include selected'),
    value: 'include',
  },
  {
    label: t('exclude_selected', 'Exclude selected'),
    value: 'exclude',
  },
  {
    label: t(
      'include_and_pre_select_selected',
      'Include and Pre-select selected'
    ),
    value: 'include-preselect',
  },
];
export const getClusionTitle = (type, t) => {
  switch (type) {
    case 'all':
      return t('include_all', 'Include all');
    case 'include':
      return t('include_selected', 'Include selected');
    case 'exclude':
      return t('exclude_selected', 'Exclude selected');
    case 'include-preselect':
      return t(
        'include_and_pre_select_selected',
        'Include and Pre-select selected'
      );

    default:
      t('include_all', 'Include all');
      break;
  }
};

export const OperatorSelectItems = (t) => [
  {
    label: t('equals_or_contains', 'Equals or Contains'),
    value: ReportFilterOperator.Equals,
    allowedInputTypes: [
      ReportFilterInputType.Date,
      ReportFilterInputType.Number,
      ReportFilterInputType.Boolean,
      ReportFilterInputType.Text,
      ReportFilterInputType.MultiSelect,
      ReportFilterInputType.SelectList,
    ],
  },
  {
    label: t('greater_than', 'Greater than'),
    value: ReportFilterOperator.GreaterThan,
    allowedInputTypes: [
      ReportFilterInputType.Date,
      ReportFilterInputType.DateTime,
      ReportFilterInputType.Number,
    ],
  },
  {
    label: t('less_than', 'Less than'),
    value: ReportFilterOperator.LessThan,
    allowedInputTypes: [
      ReportFilterInputType.Date,
      ReportFilterInputType.DateTime,
      ReportFilterInputType.Number,
    ],
  },
  {
    label: t('in_between', 'In between'),
    value: ReportFilterOperator.InBetween,
    allowedInputTypes: [
      ReportFilterInputType.Date,
      ReportFilterInputType.DateTime,
      ReportFilterInputType.Number,
    ],
  },
];
export const BooleanSelectItems = (t) => [
  {
    label: t('all', 'Alle'),
    value: 'unset',
  },
  {
    label: t('YES', 'Ja'),
    value: 'true',
  },
  {
    label: t('NO', 'Nei'),
    value: 'false',
  },
];

export const InputTypeItems = (t) => [
  {
    label: t('date', 'Date'),
    value: ReportFilterInputType.Date,
    dataTypes: ['datetime2', 'date'],
  },
  {
    label: t('number', 'Number'),
    value: ReportFilterInputType.Number,
    dataTypes: ['int', 'bigint', 'double precision', 'float'],
  },
  {
    label: t('checkbox', 'Checkbox'),
    value: ReportFilterInputType.Boolean,
    dataTypes: ['bit'],
  },
  {
    label: t('text', 'Text'),
    value: ReportFilterInputType.Text,
    dataTypes: ['varchar', 'nvarchar'],
  },
  {
    label: t('multi_select', 'Multi-select'),
    value: ReportFilterInputType.MultiSelect,
    dataTypes: ['varchar', 'nvarchar'],
  },
  {
    label: t('single_select', 'Single-select'),
    value: ReportFilterInputType.SelectList,
    dataTypes: ['varchar', 'nvarchar'],
  },
];

export const sqlColumnIsListType = (
  potentialColumns: BaseQueryColumnInfo[],
  values
) => {
  var columnInfo = potentialColumns.find((x) => x.name === values.sqlColumn);
  if (!columnInfo) {
    return false;
  }
  return (
    columnInfo.dataType === 'varchar' || columnInfo.dataType === 'nvarchar'
  );
};
export const getColumnInfo = (
  potentialColumns: BaseQueryColumnInfo[],
  values
) => {
  return potentialColumns.find((x) => x.name === values.sqlColumn);
};

export const getFieldComponent = (inputType: ReportFilterInputType) => {
  switch (inputType) {
    case ReportFilterInputType.Boolean:
      return CubitCheckboxAdapter;
    case ReportFilterInputType.Number:
      return CubitNumericFieldAdapter;
    case ReportFilterInputType.Text:
      return CubitTextFieldAdapter;
    case ReportFilterInputType.Date:
      return CubitDatePickerAdapter;
    case ReportFilterInputType.DateTime:
      return CubitDateTimePickerAdapter;
  }
  return null;
};
export const getFilterType = (inputType: ReportFilterInputType, t) => {
  switch (inputType) {
    case ReportFilterInputType.Boolean:
      return t('boolean', 'Boolean');
    case ReportFilterInputType.Number:
      return t('number', 'Number');
    case ReportFilterInputType.Text:
      return t('text', 'Text');
    case ReportFilterInputType.Date:
      return t('date', 'Date');
    case ReportFilterInputType.DateTime:
      return t('date_time', 'DateTime');
    case ReportFilterInputType.MultiSelect:
      return t('multi_select', 'Multi-select');
    case ReportFilterInputType.SelectList:
      return t('single_select', 'Single-select');
  }
  return null;
};

export const getOperatorValue = (operator: ReportFilterOperator, t) => {
  switch (operator) {
    case ReportFilterOperator.Equals:
      return t('equals_or_contains', 'Equals or contains');
    case ReportFilterOperator.GreaterThan:
      return t('greater_than', 'Greater than');
    case ReportFilterOperator.LessThan:
      return t('less_than', 'Less than');
    case ReportFilterOperator.InBetween:
      return t('in_between', 'In between');
  }
};

export const getFieldNameByOperator = (operator: ReportFilterOperator, t) => {
  switch (operator) {
    case ReportFilterOperator.Equals:
    case ReportFilterOperator.GreaterThan:
      return 'defaultValueMin';
    case ReportFilterOperator.LessThan:
      return 'defaultValueMax';
  }
};

export const getDefaultValue = (operator: ReportFilterOperator, values) => {
  switch (operator) {
    case ReportFilterOperator.Equals:
    case ReportFilterOperator.GreaterThan:
      return values.defaultValueMin;
    case ReportFilterOperator.LessThan:
      return values.defaultValueMax;
  }
};
