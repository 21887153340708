import { Fragment, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IconButton, InputBase, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../redux/app-store';
import { useTranslation } from 'react-i18next';
import { DashboardReportGroup } from '../components/dashboard/dashboard-report-group';
import { TopBar } from '../components/top-bar';
import { TopBarUser } from '../components/top-bar-user';
import { LanguagePicker } from '../components/language-picker';
import { LogoutButton } from '../components/logout-button';
import { ReportCategory } from '../components/dashboard/report-category';
import React from 'react';
import { AddReport } from '../components/dashboard/add-report';
import SearchIcon from '@mui/icons-material/Search';
import { DashboardActions } from '../redux/dashboard/dashboard-actions';
import ReportGroupsDialogV2 from '../components/dashboard/add-report-groups-dialog-v2';
const useStyles = makeStyles((theme: Theme) => ({
  allReports: {
    width: '100%',
    display: 'flex',
    marginBottom: '24px',
    flexDirection: 'column',
  },
  allReportsHeader: {
    backgroundColor: '#d9dde8',
    height: '50px',
    width: '100%',
    padding: '10px',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  searchContainer: {
    padding: '13px 10px',
    backgroundColor: '#fff',
  },
  buttonsRow: {
    display: 'flex',
    padding: '20px 0px 20px',
  },
  reportCategories: {
    display: 'flex',
  },
  searchFieldContainer: {
    margin: '0 0 2px 0',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.25)',
    padding: '0',
    backgroundColor: '#fff',
    borderRadius: '5px',
    border: '1px #000 solid',
  },
  searchFieldIconButton: {
    padding: '10px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '0',
    borderTopLeftRadius: '0',
    boxShadow: ' 0 0 2px 0 rgba(0, 0, 0, 0.25)',
    '&.MuiButtonBase-root': {
      backgroundColor: '#3f558c',
      borderRadius: '4px',
      color: '#fff',
      width: '50px',
    },
  },
}));
type ReportsPageProps = RouteComponentProps<{
  reportGroupCode?: string;
  reportCode?: string;
}>;

const DashboardPage: React.FC<ReportsPageProps> = (props: ReportsPageProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchGroupValue, setSearchGroupValue] = useState('');
  const [expanded, setExpanded] = useState(false);
  const searchValue = useSelector(
    (state: AppState) => state.dashboard.searchTerm
  );
  const reportGroups = useSelector(
    (state: AppState) => state.reports.reportGroupList
  );

  const reportList =
    reportGroups &&
    reportGroups.map((reportGroup, index) => {
      const reportsWithId = reportGroup.reports.map((report, reportIndex) => ({
        ...report,
        id: `${index + 1}-${reportIndex + 1}`,
      }));

      return {
        id: index + 1,
        ...reportGroup,
        reports: reportsWithId,
      };
    });
  useEffect(() => {
    reportList && searchValue.length >= 3
      ? reportList
          .map((reportGroup) => {
            const filteredReports = reportGroup.reports.filter((report) =>
              report.label.toLowerCase().includes(searchValue.toLowerCase())
            );
            const sortedReports = filteredReports.sort(
              (a, b) => a.sortId - b.sortId
            );
            return { ...reportGroup, reports: sortedReports };
          })
          .filter((reportGroup) => reportGroup.reports.length > 0)
      : reportList
          .map((reportGroup) => {
            const filteredReports = reportGroup.reports.filter((report) =>
              report.label.toLowerCase().includes(''.toLowerCase())
            );
            const sortedReports = filteredReports.sort(
              (a, b) => a.sortId - b.sortId
            );
            return { ...reportGroup, reports: sortedReports };
          })
          .filter((reportGroup) => reportGroup.reports.length > 0);
  }, [searchValue, dispatch, reportList]);

  const favoriteReports = useSelector(
    (state: AppState) => state.reports.reportFavoriteList
  );

  const emptyGroups =
    reportGroups && reportGroups.filter((group) => group.reports.length === 0);

  const filteredGroups = emptyGroups?.filter((group) =>
    group.label.toLowerCase().includes(searchGroupValue.toLowerCase())
  );
  const handleGroupSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchGroupValue(event.target.value);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(DashboardActions.actionDashboardSetSearchTerm(event.target.value));
  };

  const filteredAndSortedReports =
    reportList && searchValue.length >= 3
      ? reportList
          .map((reportGroup) => {
            const filteredReports = reportGroup.reports.filter((report) =>
              report.label.toLowerCase().includes(searchValue.toLowerCase())
            );
            const sortedReports = filteredReports.sort(
              (a, b) => a.sortId - b.sortId
            );
            return { ...reportGroup, reports: sortedReports };
          })
          .filter((reportGroup) => reportGroup.reports.length > 0)
      : reportList
          .map((reportGroup) => {
            const filteredReports = reportGroup.reports.filter((report) =>
              report.label.toLowerCase().includes(''.toLowerCase())
            );
            const sortedReports = filteredReports.sort(
              (a, b) => a.sortId - b.sortId
            );
            return { ...reportGroup, reports: sortedReports };
          })
          .filter((reportGroup) => reportGroup.reports.length > 0);

  return (
    <Fragment>
      <TopBar
        pageTitle='Reports'
        leftElements={[]}
        rightElements={[
          <TopBarUser key='top-bar-user' />,
          <LanguagePicker key='language-picker' />,
          <LogoutButton key='logout-button' />,
        ]}></TopBar>
      <div className='pageContainer'>
        <div className={styles.buttonsRow}>
          <AddReport reportGroups={reportList} />
          <ReportGroupsDialogV2 />
        </div>
        <div className={styles.reportCategories}>
          <ReportCategory
            expanded={expanded}
            setExpanded={setExpanded}
            reportList={reportList}
            items={favoriteReports}
            favourites
            title={t('FAVORITES', 'Favoritter')}
          />
        </div>

        <div className={styles.allReports}>
          <div className={styles.allReportsHeader}>
            {t('ALL_REPORTS', 'Alle rapporter')}
          </div>
          <div className={styles.searchContainer}>
            <div className={styles.searchFieldContainer}>
              <InputBase
                placeholder={t('SEARCH_FOR_REPORT', 'Søk etter rapport...')}
                value={searchValue}
                onChange={handleSearchChange}
                style={{ width: '100%', padding: '0 10px' }}
              />
              <IconButton
                aria-label='search'
                className={styles.searchFieldIconButton}
                size='large'>
                <SearchIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              padding: '10px',
              backgroundColor: '#fff',
            }}>
            {filteredAndSortedReports &&
              filteredAndSortedReports.map((reportGroup) => (
                <DashboardReportGroup
                  key={reportGroup.code}
                  reportGroup={reportGroup}
                />
              ))}
          </div>
        </div>
        <div className={styles.allReports}>
          <div className={styles.allReportsHeader}>
            {t('ALL_REPORT_GROUPS', 'Alle rapportgrupper')}
          </div>
          <div className={styles.searchContainer}>
            <div className={styles.searchFieldContainer}>
              <InputBase
                placeholder={t(
                  'SEARCH_FOR_REPORT_GROUP',
                  'Søk etter rapportgruppe...'
                )}
                value={searchGroupValue}
                onChange={handleGroupSearchChange}
                style={{ width: '100%', padding: '0 10px' }}
              />
              <IconButton
                aria-label='search'
                className={styles.searchFieldIconButton}
                size='large'>
                <SearchIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              padding: '10px',
              backgroundColor: '#fff',
            }}>
            {filteredGroups &&
              filteredGroups.map((reportGroup) => (
                <DashboardReportGroup
                  key={reportGroup.code}
                  reportGroup={reportGroup}
                />
              ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(DashboardPage);
