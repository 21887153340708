import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColumnText } from '../../config-view/config-helper';

const CubitAutocompleteAdapter: React.FC = (props: any) => {
  const {
    input: { onChange },
    options,
    setSelectedFilter,
    setSelectedColumn,
    selectedColumn,
    selectedFilter,
    formcontrolprops,
    meta,
    label,
    initialValue,
    ...rest
  } = props;
  const { t } = useTranslation();
  const columnText = ColumnText(t);
  const initialOption =
    options?.find((option) => option.name === initialValue) || null;
  const initialValueObj = initialOption
    ? {
        label: columnText(initialOption?.name),
        value: initialOption?.name,
        group: initialOption?.group,
        groupLabel: initialOption?.groupLabel,
      }
    : {
        label: '',
        value: '',
        group: '',
        groupLabel: '',
      };

  return (
    <FormControl variant='standard' {...formcontrolprops}>
      <FormGroup row>
        <Autocomplete
          {...rest}
          filterSelectedOptions
          onChange={(event, value: any) => {
            onChange(value.value);
            setSelectedFilter
              ? setSelectedFilter({
                  ...selectedFilter,
                  sqlColumn: value?.value ? value?.value : null,
                })
              : setSelectedColumn({
                  ...selectedColumn,
                  sqlColumn: value?.value ? value?.value : null,
                });
          }}
          defaultValue={initialValueObj ? initialValueObj : {}}
          id='selectColumn'
          options={options.map((x) => ({
            label: columnText(x?.name),
            value: x?.name,
            group: x?.group,
            groupLabel: x?.groupLabel,
          }))}
          valueIsObject
          getOptionLabel={(option: any) => option.label}
          fullWidth={true}
          groupBy={(option: any) => option.groupLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={'standard'}
              label={t('SQL_COLONNE', 'SQL kolonne')}
            />
          )}
        />
      </FormGroup>
    </FormControl>
  );
};

export { CubitAutocompleteAdapter };
