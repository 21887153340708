import { Button } from '@mui/material'

type Props = {
    text: string
    onClick?: () => void
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
}
export const WhiteOutlinedButton: React.FC<Props> = ({ text, onClick, startIcon, endIcon }) => {
    return (
        <Button
            sx={{
                color: '#ffffff',
                borderColor: '#ffffff',
                '.MuiSvgIcon-root': {
                    fill: '#ffffff',
                },
                '&:hover': {
                    borderColor: '#ffffff',
                },
            }}
            variant={'outlined'}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={onClick}
        >
            {text.toUpperCase()}
        </Button>
    )
}
