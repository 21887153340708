import { Observable } from 'rxjs';
import { DownloadFileViewModel } from '../models/download-file';
import { DynamicReport } from '../models/dynamic-report';
import { FilterObject } from '../models/filter-object';
import { ReportFilterInfo, ReportFilterOption } from '../models/report-filter';
import { ReportGroupInfo, ReportInfo } from '../models/report-info';
import { UserConfig } from '../models/user-config';
import { FetchService } from '../services/FetchService';

export default class ReportsClient {
  public static getReport(
    reportCode: string,
    filter: FilterObject
  ): Observable<DynamicReport> {
    return FetchService.DoWrappedPost(`/reports/report/${reportCode}`, filter);
  }
  public static getReportExcel(
    groupCode: string,
    filter: FilterObject
  ): Observable<DownloadFileViewModel> {
    return FetchService.DoWrappedPost(`/reports/excel/${groupCode}`, filter);
  }
  public static getCountReportList(): Observable<ReportGroupInfo[]> {
    return FetchService.DoWrappedGet(`/reports/count-list`);
  }
  public static getReportFilters(
    groupCode: string
  ): Observable<ReportFilterInfo[]> {
    return FetchService.DoWrappedGet(`/reports/filters/${groupCode}`);
  }
  public static getReportList(): Observable<ReportGroupInfo[]> {
    return FetchService.DoWrappedGet(`/reports/list`);
  }
  public static getReportFavoritesList(): Observable<ReportGroupInfo[]> {
    return FetchService.DoWrappedGet(`/reports/list/favorites`);
  }
  public static getUserConfig(): Observable<UserConfig> {
    return FetchService.DoWrappedGet(`/reports/user-config`);
  }
  public static loadOptions(
    filterId: number,
    page: number
  ): Observable<ReportFilterOption[]> {
    return FetchService.DoWrappedGet(
      `/reports/filter/${filterId}?page=${page}`
    );
  }
}
